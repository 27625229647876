import React, { Component } from 'react';
import { Slider, Radio } from '../../../../../../../styleguide';
import _ from 'lodash';
const MAX_PX = 1000;
const MAX_PERCENT = 100;

class Size extends Component {
  updateSize = (type, value) => {
    const size = _.cloneDeep(this.props.value);
    size[type] = value;
    if (type === 'type' && value === '%' && size['value'] > MAX_PERCENT) size['value'] = MAX_PERCENT;
    if (type === 'type' && value === 'px' && size['value'] > MAX_PX) size['value'] = MAX_PX;
    this.props.updateSetting(this.props.settingKey, size);
  }

  render() {
    const { value, settingsTitle, isButton } = this.props;
    return (
      <div>
        <div className="set-flexalign">
          <h4>{settingsTitle}</h4>
          <div>
            <Radio.Group value={value.type} onChange={e => this.updateSize('type', e.target.value)}>
              <Radio.Button value="auto">auto</Radio.Button>
              <Radio.Button value="px">px</Radio.Button>
              <Radio.Button value="%">%</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className="option-inputs" style={{ padding: '0 10px', marginTop: value.type !== 'auto' ? '20px' : 0 }}>
          {value.type === 'px' ? (
            <Slider min={isButton ? 44 : 1} max={MAX_PX} step={1}
              onChange={newVal => this.updateSize('value', newVal)}
              tipFormatter={label => `${label}px`}
              value={typeof value.value === 'number' ? value.value : 0}
            />
          ) : null}
          {value.type === '%' ? (
            <Slider min={1} max={MAX_PERCENT} step={1}
              onChange={newVal => this.updateSize('value', newVal)}
              tipFormatter={label => `${label}%`}
              value={typeof value.value === 'number' ? value.value : 0}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default Size;
