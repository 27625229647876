import React, { useEffect, useMemo, useState } from "react";
import { RenderLimitedChips} from "../../../../../../styleguide";
import { Button, Col, Divider, Flex, Row, Space, Switch, Tabs, Tag, theme, Typography } from "antd";
import { OptInAndOptOut, LanguageTranslations, VendorManagement, ChoiceSelection, RegionMapping, AdvancedSettings} from "../../Steps";
import { region_title_index_map } from "../../../../../../constants";
import PrivacyChoicesTable from "./PrivacyChoicesTable";
import { getOrderedStepsKeys, getStepsDataFieldMap } from "../../../../../common/SequentialNavigationWizard/SequentialLeftNav";
import { fromJS } from "immutable";
import { scrollToElement } from "../../../../helper";
import { RightOutlined, SelectOutlined } from "@ant-design/icons";

const Summary = (props) => {
  const stepsKeyNameMap = getStepsDataFieldMap(fromJS(props.stepsData), 'title')
  delete stepsKeyNameMap.get('summary');
  const [activeKey, setActiveKey] = useState("name_property_selection")

  const { token } = theme.useToken();

//selected properties --
  const indexSiteNameMap = useMemo(() =>
      props.sites.toJS().reduce(function (map, obj) {
        map[obj.id] = obj.domain;
        return map;
      }, {}),
    [props.sites]
  );
  const selectedProperties = props.usPrivacyRegulation.siteIds.map(
    (siteId) => indexSiteNameMap[siteId]
  );
  const propertyChips = selectedProperties.map((s) => (<Tag>{s}</Tag>))
//--selected properties
//just to redeploy
//selected territories--
  const territoriesChips = props.summaryPageData.geoTerritories
    .map( territory => {
      if(territory.key !== 'OTHER' && territory.included.length) {
        return (
          <Tag>
            {territory.key} ({territory.included.length})
          </Tag>
        )
      } else if(territory.included.length) {
        return territory.included.map(code => <Tag>{region_title_index_map[code]}</Tag> )
      }
    }).flat()

//--selected territories

  const generateStepSummray = (stepKey) => {
    let component;
    switch (stepKey) {
      case "name_property_selection":
        component = (
          <Col className="step" id="name_property_selection">
            <Flex justify="space-between">
              <Typography.Title level={5}>Name and Properties</Typography.Title>
              <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
            </Flex>
            <div className="container">
              <Typography.Text>Name: {props.usPrivacyRegulation.name}</Typography.Text>
              <Divider/>
              <Space direction="vertical" size={token.marginXS}>
                <Space direction="vertical" size={token.marginXXS}>
                  <Typography.Text>Property Selection</Typography.Text>
                  <Typography.Text type="secondary">Select the properties or property groups you wish these regulation settings to apply to.</Typography.Text>
                </Space>
                <RenderLimitedChips chips={propertyChips} cutOff={10}/>
              </Space>
            </div>
          </Col>
        );
        break;
      case "industry_framework":
        component = (
          <Col className="step" id="industry_framework">
            <Flex justify="space-between">
              <Typography.Title level={5}>Framework Signals and Territories</Typography.Title>
              <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
            </Flex>
            <div className="container">
              <Typography.Text>{props.usPrivacyRegulation?.signatoryId ? <>GPP MSPS Identification ID: <b>{props.usPrivacyRegulation.signatoryId}</b> {props.usPrivacyRegulation.signatoryName ?? ""}</> : <>No Identification ID added</>}</Typography.Text>
              <Divider/>
              <Space direction="vertical" size={token.marginXS}>
                <Space direction="vertical" size={token.marginXXS}>
                  <Typography.Text>Framework Territories</Typography.Text>
                  <Typography.Text type="secondary">Choose the territories where these privacy regulation settings should apply.</Typography.Text>
                </Space>
                <RenderLimitedChips chips={territoriesChips} cutOff={10}/>
              </Space>
            </div>
          </Col>
        );
        break;
      case "select_puropses":
        component = (
          <Col className="step" id="select_puropses">
            <Flex justify="space-between">
              <Typography.Title level={5}>Privacy Choices</Typography.Title>
              <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
            </Flex>
            <Typography.Text type="secondary">Select the data processing activities for which your users will be able to make choices over.</Typography.Text>
            <div className="container">
              <PrivacyChoicesTable usPrivacyRegulation={props.usPrivacyRegulation}/>
            </div>
          </Col>
        );
        break;
      case "language_translations":
        component = (
          <Col className="step" id="language_translations">
            <Space direction="vertical" size={token.marginXXS}>
              <Flex justify="space-between">
                <Typography.Title level={5}>Language & Translation Settings</Typography.Title>
                <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
              </Flex>
              <Typography.Text type="secondary">On the previous screen you picked the privacy choices that you wish to present to your users. In this section you will be able to change the language used to communicate those privacy choices to the user and add translations.</Typography.Text>
            </Space>
            <div className="container">
              <LanguageTranslations
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
                isStateFlow={props.isStateFlow}
              />
            </div>
          </Col>
        );
        break;
      case "vendor_management":
        component = (
          <Col className="step" id="vendor_management">
            <Space direction="vertical" size={token.marginXXS}>
              <Flex justify="space-between">
                <Typography.Title level={5}>Vendor Management</Typography.Title>
                <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
              </Flex>
              <Typography.Text type="secondary">Choose the vendors that you use and map them to the privacy choices they process data for.</Typography.Text>
            </Space>
            <div className="container">
              <VendorManagement
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
              />
            </div>
          </Col>
        );
        break;
      case "opt_in_opt_out":
        component = (
          <Col className="step" id="opt_in_opt_out">
            <Space direction="vertical" size={token.marginXXS}>
              <Flex justify="space-between">
                <Typography.Title level={5}>Opt In/Opt Out Events</Typography.Title>
                <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
              </Flex>
              <Typography.Text type="secondary">This section enables you to employ code hooks that are triggered when a user is in either an opted in or opted out state for each of the privacy choices or vendors that you have selected.</Typography.Text>
            </Space>
            <div className="container">
              <OptInAndOptOut
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
              />
            </div>
          </Col>
        );
        break;
      case "signal_settings":
        component = (
          <Col className="step" id="signal_settings">
            <Space direction="vertical" size={token.marginXXS}>
              <Flex justify="space-between">
                <Typography.Title level={5}>Advanced Settings</Typography.Title>
                <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
              </Flex>
            </Space>
            <div className="container">
              <AdvancedSettings
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
              />
            </div>
          </Col>
        );
        break;
      case "choice_selection":
        component = (
          <Col className="step" id="choice_selection">
            <Space direction="vertical" size={token.marginXXS}>
              <Flex justify="space-between">
                <Typography.Title level={5}>Choices Selection</Typography.Title>
                <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
              </Flex>
              <Typography.Text type="secondary">Select the relevant data processing activities your company engages in.</Typography.Text>
            </Space>
            <div className="container">
              <ChoiceSelection
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
                stepsData={fromJS(props.stepsData)}
                isNoFrameworkFlow={props.isNoFrameworkFlow}
              />
            </div>
          </Col>
        );
        break;
      case "region_mapping":
        component = (
          <Col className="step" id="region_mapping">
            <Space direction="vertical" size={token.marginXXS}>
              <Flex justify="space-between">
                <Typography.Title level={5}>Region Mapping</Typography.Title>
                <Button type="link" icon={<SelectOutlined/>} iconPosition="end" onClick={() => props.goToStep(stepKey)}>Go to step</Button>
              </Flex>
              <Typography.Text type="secondary">We have configured the default consent options for privacy choices on a per state basis, based on the latest legislation. You can make modifications using the drop-down menus in the matrix below.</Typography.Text>
            </Space>
            <div className="container">
              <RegionMapping
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
                isNoFrameworkFlow={props.isNoFrameworkFlow}
              />
            </div>
          </Col>
        );
        break;
    }
    return component;
  };

  const components = props.stepArray.map((stepKey) => {
    return generateStepSummray(stepKey);
  });

  useEffect(()=>{
    const summaryContainer = document.getElementById('usp-summary-page')
    summaryContainer.addEventListener('scroll', function() {
      let sections = document.querySelectorAll('.summary-components .step');
      
      for (let index = 0; index < sections.length; index++) {
        let section = sections[index];
        let rect = section.getBoundingClientRect();

        if (rect.top >= 0 && rect.top <= summaryContainer.clientHeight) {
          setActiveKey(section.getAttribute('id'))
          if(index !== sections.length - 2) break;
        }
      }
    });
  },[])
  
  const handleOnTabClick = (key) => {
    setActiveKey(key);
    scrollToElement(key);
  }

  return (
    <div>
      <div className="summary-header">
        <div className="step-header">
          <Typography.Title level={4}>Summary</Typography.Title>
        </div>
        <Tabs
          activeKey={activeKey}
          items={getOrderedStepsKeys(fromJS(props.stepsData)).filter((key) => key !== "summary").map((key) => ({key, label: stepsKeyNameMap.get(key)}))}
          onChange={(key) => handleOnTabClick(key)}
          more={{icon: <RightOutlined/>}}
        />
      </div>
      <Row gutter={[0, 16]} className="summary-components"  id="usp-summary-page">
        {components}
      </Row>
    </div>
  );
};

export default Summary;
