import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Flex, Menu, Space, Tag, Typography, Button, Tabs, Alert } from 'antd';
import { findObjectByKeyRef, getAllMainPurposes, getTranslationLanguages, getAllRegulationPurposes, getSelectedTranslationLanguages, getUniqueObjectsByProperty } from "./helper";
import { PlusOutlined, MinusOutlined, UploadOutlined, EyeOutlined } from "@ant-design/icons"
import { SelectDropdown} from "../../../../../../styleguide";
import { COUNTRY_LANGUAGES } from '../../../../../../constants';
import BulkUploadModal from "./BulkUploadModal";
import PreviewModal from "./PreviewModal";
import TranslationTable from "./TranslationTableUpdated";
import { MESSAGE_SENTIMENT_TOOLTIP, renderInfoTip } from "../../../helper";

const { TabPane } = Tabs;


const LanguageTranslations = (props) => {
  const [regulationCategories, setRegulationCategories] = useState(props.usPrivacyRegulation.categories)
  const [selectedPurpose, setSelectedPurpose] = useState(props.usPrivacyRegulation.categories[0])
  const [selectedLang, setSelectedLanguage] = useState(["EN"])
  const [data, setData] = useState([])
  const [showBulkUpload, setShowBulkUpload] = useState(false)
  const [openKeys, setOpenKeys] = useState([])
  const [showMessageTone, setShowMessageTone] = useState(false);
  const [showReset, setShowReset] = useState({})
  const [selectedSubMenuKey, setSelectedSubMenuKey] = useState([]);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']));
  const systemStack = useSelector(state => state.usPrivacyReducerState.getIn(['stacks', 'value']));
  const systemPurposesJS = systemPurposes.toJS()
  const systemStackJS = systemStack.toJS()
  const [activeTab, setActiveTab] = useState("2")
  const [showIdentificationList, setShowIdentificationList] = useState(props.usPrivacyRegulation.showIdentificationList)
  const [showPreview, setShowPreview] = useState(false)
  const [bulkChoice, setBulkChoice] = useState(null)

  const CustomEnglishInfoTip = <Alert style={{position: 'absolute', top: "120px", marginLeft: "16px"}} type="warning" showIcon message={<>Please add translation for English (default) language to be able to add translations for other languages. </>} />

  let tabs = [
    { key: 1, title: "Description" ,infoText:"This description is presented to users in the message ahead of giving them the choice to opt in or out of the data processing activities listed. It should give users transparency and clarity into how your business is using the data you collect and give them information on how to exercise their choices."},
    { key: 2, title: "Choice Text", infoText:"This text directs the user to make choice for this specific data processing activity" }
  ]

  if(!props.isStateFlow) {
    tabs.push({ key: 3, title: "Privacy Policy Section Link", infoText:"This text in the message displays how the user can access the section of the privacy policy with the disclosures for this privacy choice." })
  }
 
  const ERROR_TEXT = "Please fill the empty translation field for default language (English)."
  const NO_TRANSLATION_TEXT = "No Translations found"

  const tabsFieldTranslationkey = { "1": "descriptions", "2": "names", "3": "privacyPolicySectionText", "4": "identificationList" }

  const tabsFieldNamekey = { "1": "description", "2": "name", "3": "privacyPolicySectionText", "4": "identificationList" }
  const tabFieldNegativeNameKey = { "1": "negativeDescription", "2": "negativeName"}

  const getChoiceId = (selPurpose) => {
    if(selPurpose.type === "SYSTEM_STACK"){
      return selPurpose?.systemStackRef.id
    }else if(selPurpose.type === "SYSTEM_PURPOSE"){
      return selPurpose.purposeRef.id
    }else if(selPurpose.type === "CUSTOM"){
      return selPurpose.id
    }
  }

  const selectedKey = getChoiceId(selectedPurpose)

  const messageTones = [{
    label: 'Affirmative',
    value: false
  },
  {
    label: 'Negative',
    value: true
  },
  ]

  const uniqueTranslationLanguages = getTranslationLanguages(regulationCategories, systemPurposesJS,systemStackJS)
  const allRegulationPurposes = getAllRegulationPurposes(regulationCategories)
  const allMainPurposes = getAllMainPurposes(regulationCategories)
  let selectedTransLanguages = getSelectedTranslationLanguages(regulationCategories).map(l => l.toUpperCase())
  
  uniqueTranslationLanguages.unshift("en")
  if (!selectedTransLanguages.includes("EN")) {
    selectedTransLanguages.unshift("EN")
  }

  const dropdownLanguages = COUNTRY_LANGUAGES.sort((a, b) => {
    // Move English language to the top
    if (a.language === "English") {
      return -1;
    } else if (b.language === "English") {
      return 1;
    } else {
      return 0;
    }
  });

  const languageNames = COUNTRY_LANGUAGES.filter(c => selectedTransLanguages.includes(c.code)).map(l => l.language)

  let languageOptions = dropdownLanguages.toJS().map((l, i) => ({
    label: l.code === 'EN' ? l.language + " (Default)" : l.language,
    value: l.code,
    disabled: l.code === 'EN' ? true : false
  }));

  const purposeOptions = allRegulationPurposes?.map((purpose) => (
    {
      label: purpose.privacyChoice ?? purpose.name,
      value: purpose?.purposeRef?.id || purpose?.systemStackRef?.id || purpose?.id,
    }
  ))

  const descriptionOptions = allMainPurposes?.map((purpose) => (
    {
      label: purpose?.privacyChoice ?? purpose?.name,
      value: purpose?.purposeRef?.id || purpose?.systemStackRef?.id || purpose?.id,
    }
  ))

  useEffect(() => {
    props.usPrivacyRegulation.categories = regulationCategories
    if(!props.readOnly) props.resetValidationError();
  }, [regulationCategories]);

  useEffect(() => {
    if (JSON.stringify(props.usPrivacyRegulation.categories) !== JSON.stringify(regulationCategories)) {
      const categories = props.usPrivacyRegulation.categories
      setSelectedPurpose(categories[0])
      setRegulationCategories(categories)
    }
  }, [props.usPrivacyRegulation.categories])

  useEffect(() => {
    const categories = props.usPrivacyRegulation.categories
    const categoriesFiltered = getUniqueObjectsByProperty(categories, 'defaultLegalBasis')
    const optOutCategories = categoriesFiltered.filter(c => c.defaultLegalBasis === "OPT-OUT" && c.type !== "CUSTOM");
    const hasIdentificationList = optOutCategories.some(c => c?.translations?.identificationList)
    if (optOutCategories.length > 0 && hasIdentificationList) {
      setShowIdentificationList(true)
    } else {
      setShowIdentificationList(false)
    }
  }, [props.usPrivacyRegulation.categories])

  useEffect(() => {
      if (selectedTransLanguages.length > 0) {
        const selectedTransLanguagesUpper = selectedTransLanguages.map(l => l.toUpperCase());
        handleSelectedLanguage(selectedTransLanguagesUpper, activeTab);
        setSelectedLanguage(selectedTransLanguages)
      } else {
        handleSelectedLanguage(selectedLang, activeTab);
      }
      handleMenuClick(selectedKey, activeTab)
      updateTableData(regulationCategories, selectedTransLanguages, activeTab)
  }, [regulationCategories])

  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const updateRegulationCategories = (purpose) => {
    let updatedRegulationCategories = _.cloneDeep(regulationCategories);
    let updatedData = updatedRegulationCategories.map((cat, index) => {
      if (cat.type === "SYSTEM_STACK") {
        let updatedIndex;
        if (purpose.type === "SYSTEM_STACK") {
          if (cat.systemStackRef.systemId === purpose.systemStackRef.systemId) {
            return purpose
          }
        } else if(purpose.type === "SYSTEM_PURPOSE"){
          updatedIndex = cat.categories.findIndex(
            (c) => c.purposeRef.systemId === purpose.purposeRef.systemId
          );
        }

        if (updatedIndex && updatedIndex !== -1) {
          cat.categories[updatedIndex] = purpose;
        }
      } else if (purpose?.purposeRef && (cat?.purposeRef?.systemId === purpose?.purposeRef?.systemId)) {
        return purpose;
      }else if(cat?.id === purpose?.id){
        return purpose;
      }
      return cat; // Return the modified or unmodified object
    });
    setRegulationCategories(updatedData)
  }

  const getPurposesContentByTab = (activeTab, selectedPurpose) => {
    const categoryId = selectedPurpose?.purposeRef ? selectedPurpose?.purposeRef?.systemId : selectedPurpose?.systemStackRef?.systemId;
    let systemCategory = null
    if (selectedPurpose?.systemStackRef) {
      systemCategory = findObjectByKeyRef(systemStackJS, 'categories', 'systemId', categoryId);
    } else if(selectedPurpose?.purposeRef) {
      systemCategory = findObjectByKeyRef(systemPurposesJS, 'categories', 'systemId', categoryId);
    }
    const fieldkey = tabsFieldTranslationkey[activeTab]
    const isMessageToneSupported = activeTab === "1" || activeTab === "2"
    if(systemCategory){
      const affirmativeTranslations = (selectedPurpose?.translations && selectedPurpose?.translations?.[fieldkey] ) ? selectedPurpose?.translations[fieldkey] : { "en": systemCategory[tabsFieldNamekey[activeTab]] };
      const negativeTranslations = (selectedPurpose?.negativeTranslations && selectedPurpose?.negativeTranslations?.[fieldkey]) ? selectedPurpose?.negativeTranslations[fieldkey] : { "en": systemCategory[tabFieldNegativeNameKey[activeTab]]}
      return (selectedPurpose?.isNegative === true && isMessageToneSupported) ? negativeTranslations : affirmativeTranslations
    }else{
      return (selectedPurpose?.isNegative === false)?selectedPurpose?.translations?.[fieldkey]: selectedPurpose.negativeTranslations?.[fieldkey]
    }
  }

  const getSystemTranslations = (activeTab,purpose,selectedPurpose) => {
    const fieldkey = tabsFieldTranslationkey[activeTab]
    let affirmativeTranslations = purpose?.translations?.[fieldkey]
    affirmativeTranslations["en"] =  purpose?.[tabsFieldNamekey[activeTab]]
    const negativeTranslations = purpose?.negativeTranslations?.[fieldkey] ? purpose?.negativeTranslations?.[fieldkey]: {}
    negativeTranslations["en"] = purpose?.[tabFieldNegativeNameKey[activeTab]]
    return (selectedPurpose?.isNegative === true) ? negativeTranslations : affirmativeTranslations
  }

  const compareObjects = (obj1, obj2) => {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return true; // Difference found
        }
      }
    }
    return false; // No difference found
  }
 
  const handleMenuClick = (key,activeTab) => {
    setSelectedSubMenuKey(key)
    let selectedPurpose = findObjectByKeyRef(regulationCategories, 'categories', 'purposeRef', key)
     || findObjectByKeyRef(regulationCategories, 'categories', 'systemStackRef', key) || findObjectByKeyRef(regulationCategories, 'categories', 'id', key);
    let systemCategory = findObjectByKeyRef(systemStackJS, 'categories', 'systemId', selectedPurpose?.systemStackRef?.systemId) || findObjectByKeyRef(systemPurposesJS, 'categories', 'systemId', selectedPurpose?.purposeRef?.systemId);
  
    let translations = getPurposesContentByTab(activeTab,selectedPurpose)
    let systemCategoryTranslations = systemCategory ? getSystemTranslations(activeTab,systemCategory,selectedPurpose) : null
    const isSystemPurposes = systemStackJS[0]?.systemPurposes.includes(selectedPurpose?.purposeRef?.id);

    const selectedLanguages = selectedLang.map(l => l.toLowerCase());
    let dataSource = []

    if (translations) {
      dropdownLanguages.toJS().map((language, index) => {
        let langCode = language.code.toLowerCase()
        if (selectedLanguages.includes(langCode)) {
          const translationCodes = Object.keys(translations);
          let lang = dropdownLanguages.find(l => l.code.toLowerCase() === langCode)?.language;
          let translation = translations[langCode]
          if (translationCodes.includes(langCode) || translation !=="") {
            if (lang) {
              dataSource.push({ key: index + 1, name: lang, translation })
            }
          } else {
            dataSource.push({ key: index + 1, name: lang, translation: '' })
          }
        }
      })
    }

    /** To handle reset functionality */
    const messageTone = selectedPurpose?.isNegative
    const rkey = `${key}-${messageTone}-${activeTab}`
    if(systemCategoryTranslations && compareObjects(translations, systemCategoryTranslations)){
      setShowReset({ ...showReset, [rkey]: true });
    }else{
      setShowReset({ ...showReset, [rkey]: false });
    }
    

    setData(dataSource)
    setShowMessageTone(activeTab === "2" && !isSystemPurposes);
    setSelectedPurpose(selectedPurpose);
  }

  const getMenuItems = (key) => {
    let purposes = []
    let categories = props.usPrivacyRegulation.categories;
    categories.sort((a, b) => (a.type === 'CUSTOM' ? 1 : b.type === 'CUSTOM' ? -1 : 0));
    if (key === 1) {
      categories?.map((cat) => {
        if (cat.type === 'SYSTEM_PURPOSE') {
          purposes.push(
            <Menu.Item
              onClick={() => setShowMessageTone(false)}
              className={selectedSubMenuKey === cat.purposeRef.id ? 'selected' : ''}
              key={cat.purposeRef.id}
              title={cat.privacyChoice ?? cat.name}
            >
              {cat.privacyChoice ?? cat.name}
            </Menu.Item>
          )
        } else if(cat.type === 'SYSTEM_STACK') {
            purposes.push(
              <Menu.Item
                className={selectedSubMenuKey === cat.systemStackRef.id ? 'selected' : 'notselected'}
                key={cat.systemStackRef.id}
                onClick={() => setShowMessageTone(false)}
                title={cat.privacyChoice ?? cat.name}
              >
                {cat.privacyChoice ?? cat.name}
              </Menu.Item>
            )
        } else if (cat.type === 'CUSTOM') {
          purposes.push(
            <Menu.Item
              onClick={() => setShowMessageTone(true)}
              className={selectedSubMenuKey === (cat.id ?? cat.name) ? 'selected' : ''}
              key={cat.id ?? cat.name}
              icon={(!cat.translations || Object.keys(cat.translations).length == 0 || cat?.translations?.descriptions["en"] === "") ? renderInfoTip(ERROR_TEXT, "warning") : null}
              title={cat.privacyChoice ?? cat.name}
            >
              {cat.privacyChoice ?? cat.name}
            </Menu.Item>
          )
        }
      })
    }
    if (key === 2) {
      categories?.map((cat) => {
        if (cat.type === 'SYSTEM_PURPOSE') {
          purposes.push(
            <Menu.Item
              onClick={() => setShowMessageTone(true)}
              className={selectedSubMenuKey === cat.purposeRef.id ? 'selected' : ''}
              key={cat.purposeRef.id}
              title={cat.privacyChoice ?? cat.name}
            >
              {cat.privacyChoice ?? cat.name}
            </Menu.Item>
          )
        } else if(cat.type === 'SYSTEM_STACK') {
          let subPurposes = [];
          cat.categories.map((c) => {
            const pur = getItem(c.privacyChoice ?? c.name, c.purposeRef.id, null)
            subPurposes.push(pur)
          })
          if (subPurposes.length >= 1) {
            purposes.push(
              <Menu.SubMenu
                title={cat.privacyChoice ?? cat.name }
                key={cat.systemStackRef.id}
                onTitleClick={({ key }) => { setShowMessageTone(true), handleMenuClick(key,activeTab) }}
                id={cat.systemStackRef.id}
                className={selectedSubMenuKey === cat.systemStackRef.id ? 'selected' : 'notselected'}
              >
                {subPurposes.map(p => (
                  <Menu.Item onClick={() => setShowMessageTone(false)} key={p.key} className={selectedSubMenuKey === p.key ? 'selected' : 'notselected'}>
                    {p.label}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          } else {
            purposes.push(
              <Menu.Item
                className={selectedSubMenuKey === cat.systemStackRef.id ? 'selected' : 'notselected'}
                key={cat.systemStackRef.id}
                title={cat.privacyChoice ?? cat.name}
              >
                {cat.privacyChoice ?? cat.name}
              </Menu.Item>
            )
          }
        } else if(cat.type === 'CUSTOM'){
          const translations = cat.isNegative ? cat.negativeTranslations : cat.translations;
          purposes.push(
            <Menu.Item
              onClick={() => setShowMessageTone(true)}
              className={selectedSubMenuKey === (cat.id??cat.name) ? 'selected' : ''}
              key={cat.id??cat.name}
              icon={(!translations || Object.keys(translations).length == 0 || translations.names["en"] === "") ? renderInfoTip(ERROR_TEXT, "error-icon") : null}
              title={cat.privacyChoice ?? cat.name}
            >
              {cat.privacyChoice ?? cat.name}
            </Menu.Item>
          )
        }
      })
    }
    if (key === 3) {
      const categoriesFiltered = getUniqueObjectsByProperty(categories, 'defaultLegalBasis')
      categoriesFiltered?.map((cat) => {
        if (cat.defaultLegalBasis === 'OPT-OUT' && cat.privacyPolicySectionLink) {
          purposes.push(
            <Menu.Item
              onClick={() => setShowMessageTone(false)}
              className={selectedSubMenuKey === cat.purposeRef.id ? 'selected' : ''}
              key={cat.purposeRef.id}
            >
              {"Opt Out Choice"}
            </Menu.Item>
          )
        } else if(cat.defaultLegalBasis === 'CONSENT' && cat.privacyPolicySectionLink) {
          purposes.push(
            <Menu.Item
              className={selectedSubMenuKey === cat.systemStackRef.id ? 'selected' : 'notselected'}
              key={cat.systemStackRef.id}
            >
              {"Opt In Choice"}
            </Menu.Item>
          )
        }
      })
      
    }
    if (key === 4) {
      const categoriesFiltered = getUniqueObjectsByProperty(categories, 'defaultLegalBasis')
      const optOutcategories = categoriesFiltered.filter((cat) => cat.defaultLegalBasis === 'OPT-OUT' && cat.type !== "CUSTOM");
      if (optOutcategories.length > 0) {
        optOutcategories?.map((cat) => {
            purposes.push(
              <Menu.Item
                onClick={() => setShowMessageTone(false)}
                className={selectedSubMenuKey === cat.purposeRef?.id ? 'selected' : ''}
                key={cat.purposeRef.id}
              >
                {"Opt Out Choice"}
              </Menu.Item>
            )
        })
      }
    }
    return purposes
    
  }

  const filterTransLationObject = (translations, selectedLang) => {
    const filteredTranslation = Object.entries(translations)
      .filter(([key]) => selectedLang.includes(key.toUpperCase()))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    return filteredTranslation
  }

  const checkDefaultLanguageChange = (category, systemPurpose, mode,activeTab) => {
    const fieldkey = tabsFieldTranslationkey[activeTab]
    const fieldNamekey = tabsFieldNamekey[activeTab]
    const negativeFieldNamekey = tabFieldNegativeNameKey[activeTab]
    const updatedDefaultName = (!mode)
      ? ((category?.translations && category?.translations?.[fieldkey]?.en) ? category.translations[fieldkey]?.en : category[tabsFieldNamekey[activeTab]]) :
      (category?.negativeTranslations?.[fieldkey]?.en ? category.negativeTranslations?.[fieldkey]?.en : category[tabFieldNegativeNameKey[activeTab]]);
    const systemDefaultName = (!mode) ? systemPurpose?.[fieldNamekey] : systemPurpose?.[negativeFieldNamekey];
    return updatedDefaultName !== systemDefaultName
  }

  const updateCategoryTranslation = (category, languages, systemPurpose, activeTab) => {
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab]
    const negativeFieldTranslationKey = tabFieldNegativeNameKey[activeTab]
    const fieldKey = tabsFieldNamekey[activeTab]
    const affirmativeTranslations = (category?.translations && category?.translations[fieldTranslationkey]) ? category?.translations[fieldTranslationkey] : {};
    const negativeTranslations = (category?.negativeTranslations && category?.negativeTranslations[fieldTranslationkey]) ? category?.negativeTranslations[fieldTranslationkey] : {}
    const isFirstOrSecondTab = (activeTab === "1" || activeTab === "2")
 
    languages.map(l => {
      const lang = l.toLowerCase()
      if (affirmativeTranslations && !affirmativeTranslations[lang]) {
        const isDefaultLangChanged = checkDefaultLanguageChange(category, systemPurpose, false,activeTab);
        if (systemPurpose?.translations) {
          if (lang === "en") {
            affirmativeTranslations[lang] = (systemPurpose[fieldKey] !== "") ? systemPurpose[fieldKey] : ""
          } else {
            if (isDefaultLangChanged || affirmativeTranslations[lang] === "") {
              affirmativeTranslations[lang] = ""
            } else {
              affirmativeTranslations[lang] = systemPurpose?.translations?.[fieldTranslationkey]?.[lang] || ""
            }
          }
        }
      }

      if (negativeTranslations && !negativeTranslations[lang]) {
          const isDefaultLangChanged = checkDefaultLanguageChange(category, systemPurpose, true,activeTab);
          if (systemPurpose?.negativeTranslations) {    
            if (lang === "en") {
              negativeTranslations[lang] = (systemPurpose[negativeFieldTranslationKey] !== "") ? systemPurpose[negativeFieldTranslationKey] : "";
            } else {
              if (isDefaultLangChanged || negativeTranslations[lang] === "") {
                negativeTranslations[lang] = ""
              } else {
                negativeTranslations[lang] = systemPurpose?.negativeTranslations?.[fieldTranslationkey]?.[lang] || ""
              }
            }
        }
      }
    })

    if (category.negativeTranslations) {
      category.negativeTranslations[fieldTranslationkey] = filterTransLationObject(negativeTranslations, languages) || null
    } else {
      category.negativeTranslations = {}
      category.negativeTranslations[fieldTranslationkey] = filterTransLationObject(negativeTranslations, languages) || null
    }
    if (category.translations) {
      category.translations[fieldTranslationkey] = filterTransLationObject(affirmativeTranslations, languages) || null
    } else {
      category.translations = {}
      category.translations[fieldTranslationkey] = filterTransLationObject(affirmativeTranslations, languages) || null
    }

    if (category.translations && isFirstOrSecondTab) {
      const defaultTranslation = category?.translations?.[fieldTranslationkey]["en"];
      category[fieldKey] = (defaultTranslation && defaultTranslation !== "") ? category?.translations?.[fieldTranslationkey]["en"] : ""
    }

    if (category.negativeTranslations && isFirstOrSecondTab) {
      const defaultNegTranslation = category?.negativeTranslations?.[fieldTranslationkey]["en"] ;
      category[negativeFieldTranslationKey] = (defaultNegTranslation && defaultNegTranslation !== "")? category?.negativeTranslations?.[fieldTranslationkey]["en"] : ""
    }

    return category
  }
  

  const updateTableData = (regulationCategories, selectedLang, activeTab) => {
    if (regulationCategories?.length > 0) {
      const id = getChoiceId(selectedPurpose)
      const fieldTranslationkey = tabsFieldTranslationkey[activeTab]
      const fieldKey = tabsFieldNamekey[activeTab]
      let selPurpose = findObjectByKeyRef(regulationCategories, 'categories', 'purposeRef', id) ||  findObjectByKeyRef(regulationCategories, 'categories', 'systemStackRef', id) 
      || findObjectByKeyRef(regulationCategories, 'categories', 'id', id);
      const isMessageToneSupported = activeTab === "2" || activeTab === "1";
      let translations = (selPurpose?.isNegative && isMessageToneSupported) ? selPurpose?.negativeTranslations?.[fieldTranslationkey] : selPurpose?.translations?.[fieldTranslationkey]
      if(!translations){
        translations = {}
      }
      const selectedLanguages = selectedLang.map(l => l.toLowerCase());
      let dataSource = []
      if (translations) {
        dropdownLanguages.toJS().map((language, index) => {
          let langCode = language.code.toLowerCase()
          if (selectedLanguages.includes(langCode)) {
            const translationCodes = Object.keys(translations);
            let lang = dropdownLanguages.find(l => l.code.toLowerCase() === langCode)?.language;
            let translation = translations[langCode]
            if (translationCodes.includes(langCode) || translation!=="") {
              if (lang) {
                dataSource.push({ key: index + 1, name: lang, translation })
              }
            } else {
              dataSource.push({ key: index + 1, name: lang, translation: '' })
            }
          }
        })
      }

      setData(dataSource)
    } else {
      setData([])
    }
  }

  /** called when supported language is changed */
  const handleSelectedLanguage = (value,activeTab) => {
    setSelectedLanguage(value)
    tabs.map(t => {
      const activetab = t.key.toString();
      regulationCategories?.forEach(category => {
        if (category.type === 'SYSTEM_STACK') {
          const nestedCategories = category.categories;
          if (category.systemStackRef) {
            const systemStack = findObjectByKeyRef(systemStackJS, 'categories', 'systemId', category?.systemStackRef?.systemId);
            const updatedCategory = updateCategoryTranslation(category, value, systemStack, activetab);
          }
          nestedCategories.forEach(c => {
            const systemPurpose = findObjectByKeyRef(systemPurposesJS, 'categories', 'systemId', c?.purposeRef?.systemId);
            const updatedCategory = updateCategoryTranslation(c, value, systemPurpose, activetab);
            return updatedCategory
          });
        } else if(category?.purposeRef) {
          const systemPurpose = findObjectByKeyRef(systemPurposesJS, 'categories', 'systemId', category?.purposeRef?.systemId);
          const updatedCategory = updateCategoryTranslation(category, value, systemPurpose, activetab);
          return updatedCategory
        } else if (activetab !== "3" && activetab !== "4") {
          const purpose = findObjectByKeyRef(regulationCategories, 'categories', 'id', category?.id);
          const updatedCategory = updateCategoryTranslation(category, value, purpose, activetab);
          return updatedCategory
        }
      });
      setRegulationCategories(regulationCategories)
    })
    updateTableData(regulationCategories, value, activeTab)
  }

  const checkPrivacyLink = (regulationCategories) => {
    const checkFlag = regulationCategories.some(r => r.privacyPolicySectionLink)
    return checkFlag
  }

  const onChangeTab = async (value) => {
    setActiveTab(value);
    const categoriesFiltered = getUniqueObjectsByProperty(regulationCategories, 'defaultLegalBasis')
    let defaultSelectedKey = regulationCategories[0]?.systemStackRef?.id || regulationCategories[0]?.purposeRef?.id || regulationCategories[0]?.id;
    if(value === "4") {
      const optOutcategories = categoriesFiltered.filter((cat) => cat.defaultLegalBasis === 'OPT-OUT');
      defaultSelectedKey = optOutcategories[0].purposeRef.id;
    } else if (value === "3") {
      let purposes = []
      categoriesFiltered?.map((cat) => {
        if (cat.defaultLegalBasis === 'OPT-OUT' && cat.privacyPolicySectionLink) {
          purposes.push(cat);
        } else if(cat.defaultLegalBasis === 'CONSENT' && cat.privacyPolicySectionLink) {
          purposes.push(cat);
        }
      })
      defaultSelectedKey = (purposes.length> 0)? (purposes[0]?.systemStackRef?.id ?? purposes[0]?.purposeRef?.id): selectedKey;
    } 
    handleMenuClick(defaultSelectedKey, value)
    setShowMessageTone(value === "2");
  }

  useEffect(() => {
    if (selectedTransLanguages.length > 0) {
      const selectedTransLanguagesUpper = selectedTransLanguages.map(l => l.toUpperCase());
      handleSelectedLanguage(selectedTransLanguagesUpper, activeTab);
      setSelectedLanguage(selectedTransLanguages)
    } else {
      handleSelectedLanguage(selectedLang, activeTab);
    }
  }, [selectedPurpose, activeTab])

  const handleMessageTone = (value) => {
    const purpose = { ...selectedPurpose };
    purpose.isNegative = value;
    const key = `${selectedSubMenuKey}-${value}-${activeTab}`
    if (!showReset[key]) {
      setShowReset({ ...showReset, [key]: false });
    }
    setSelectedPurpose(purpose),
    updateRegulationCategories(purpose)
  }

  const handleTranslationChanges = (data, purpose) => {
    setData(data);
    const updatedPurpose = (purpose) ? { ...purpose } : { ...selectedPurpose };
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab]
    const fieldKey = tabsFieldNamekey[activeTab]
    const negativeFieldTranslationKey = tabFieldNegativeNameKey[activeTab]
    const isFirstOrSecondTab = (activeTab === "1" || activeTab === "2")
    let translations = updatedPurpose?.isNegative
      ? updatedPurpose?.negativeTranslations ?.[fieldTranslationkey]
      : updatedPurpose?.translations?.[fieldTranslationkey]

    if (!translations) {
      translations = {}
    }

    data.map((row) => {
      let code = COUNTRY_LANGUAGES.find(l => l.language.toLowerCase() === row.name.toLowerCase())?.code.toLowerCase();
      translations[code] = row.translation
    })
    if (updatedPurpose.isNegative) {
      if (!updatedPurpose?.negativeTranslations) {
        updatedPurpose.negativeTranslations = {}
        updatedPurpose.negativeTranslations[fieldTranslationkey] = translations
      } else {
        updatedPurpose.negativeTranslations[fieldTranslationkey] = translations
      }
    } else {
      if (!updatedPurpose?.translations) {
        updatedPurpose.translations = {}
        updatedPurpose.translations[fieldTranslationkey] = translations
      } else {
        updatedPurpose.translations[fieldTranslationkey] = translations
      }
    }

    if (updatedPurpose?.translations && isFirstOrSecondTab) {
      updatedPurpose[fieldKey] = updatedPurpose?.translations?.["en"]
    }

    if (updatedPurpose?.negativeTranslations && isFirstOrSecondTab) {
      updatedPurpose[negativeFieldTranslationKey] = updatedPurpose?.negativeTranslations?.["en"]
    }

    updateRegulationCategories(updatedPurpose)
  }

  const handleTranslationChangesAfterUpload = (data, purpose,choice) => {
    setData(data);
    const activeTab = choice=== 1? "2":"1";
    setActiveTab(activeTab)
    const updatedPurpose = (purpose) ? { ...purpose } : { ...selectedPurpose };
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab]
    const fieldKey = tabsFieldNamekey[activeTab]
    let translations = updatedPurpose?.isNegative
      ? updatedPurpose?.negativeTranslations?.[fieldTranslationkey]
      : updatedPurpose?.translations?.[fieldTranslationkey]

    if (!translations) {
      translations = {}
    }

    data.map((row) => {
      let code = COUNTRY_LANGUAGES.find(l => l.language.toLowerCase() === row.name.toLowerCase())?.code.toLowerCase();
      translations[code] = row.translation
    })
    if (updatedPurpose.isNegative) {
      if (!updatedPurpose?.negativeTranslations) {
        updatedPurpose.negativeTranslations = {}
        updatedPurpose.negativeTranslations[fieldTranslationkey] = translations
      } else {
        updatedPurpose.negativeTranslations[fieldTranslationkey] = translations
      }
    } else {
      if (!updatedPurpose?.translations) {
        updatedPurpose.translations = {}
        updatedPurpose.translations[fieldTranslationkey] = translations
      } else {
        updatedPurpose.translations[fieldTranslationkey] = translations
      }
    }
    updateRegulationCategories(updatedPurpose)
  }

  const resetData = () => {
    const category = { ...selectedPurpose }
    const categoryId = category?.purposeRef ? category?.purposeRef?.systemId : category?.systemStackRef?.systemId;
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab]
    let systemCategory
    if (category.systemStackRef) {
      systemCategory = findObjectByKeyRef(systemStackJS, 'categories', 'systemId', categoryId);
    } else {
      systemCategory = findObjectByKeyRef(systemPurposesJS, 'categories', 'systemId', categoryId);
    }

    let translations = category?.isNegative
      ? systemCategory?.negativeTranslations[fieldTranslationkey]
      : systemCategory?.translations[fieldTranslationkey]

    const filteredTranslations = selectedLang.reduce((result, language) => {
      const lowercaseLanguage = language.toLowerCase();
      if (translations[lowercaseLanguage]) {
        result[lowercaseLanguage] = translations[lowercaseLanguage];
      }
      return result;
    }, {});


    if (category.isNegative) {
      if (!category?.negativeTranslations) {
        category.negativeTranslations = {}
        category.negativeTranslations[fieldTranslationkey] = filteredTranslations
      } else {
        category.negativeTranslations[fieldTranslationkey] = filteredTranslations
      }
    } else {
      if (!category?.translations) {
        category.translations = {}
        category.translations[fieldTranslationkey] = filteredTranslations
      } else {
        category.translations[fieldTranslationkey] = filteredTranslations
      }
    }
    updateRegulationCategories(category)
  }

  const formatUploadedData = (data) => {
    const obj2 = [];

    data.forEach((item) => {
      const privacyChoice = item["Privacy Choice"];
      const translations = [];

      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Privacy Choice") {
          translations.push({
            key: translations.length + 1,
            name: key,
            translation: value
          });
        }
      });

      obj2.push({
        [privacyChoice]: translations
      });
    });

    return obj2;
  };


  function findDifferentObjects(array1, array2) {
    const differentObjects = [];

    if (array1.length === 0) {
      return differentObjects; // Return an empty array if array1 is empty
    }

    const keysToCompare = Object.keys(array1[0]); // Get keys from the first object in array1

    // Iterate through the second array
    for (const obj2 of array2) {
      // Check if there is an object in the first array with matching values
      const found = array1.some((obj1) => {
        return keysToCompare.every((key) => obj1[key] === obj2[key]);
      });

      // If obj2 is not found in the first array, add it to the differentObjects array
      if (!found) {
        differentObjects.push(obj2);
      }
    }

    return differentObjects;
  }


  const uploadTranslations = (fileData, sampleData,choice) => {
    const uploadedData = formatUploadedData(fileData);
    const differentObjects = findDifferentObjects(fileData, sampleData);
    const dataChangedPurposes = differentObjects.map((obj) => obj["Privacy Choice"])
    const activeChoice = choice === 1 ? "2" : "1";
    setBulkChoice(activeChoice)
    uploadedData.map((row, index) => {
      let purposeName = Object.keys(row)[0]
      let purpose = findObjectByKeyRef(regulationCategories, 'categories', 'name', purposeName) || findObjectByKeyRef(regulationCategories, 'categories', 'privacyChoice', purposeName)
      if (dataChangedPurposes.includes(purposeName)) {
        const purposeId = purpose?.purposeRef?.id || purpose?.systemStackRef?.id || purpose?.id;
        const key = `${purposeId}-${purpose?.isNegative}-${activeChoice}`
        setShowReset(prevShowReset => ({ ...prevShowReset, [key]: true }));
      }
      handleTranslationChangesAfterUpload(row[purposeName], purpose, choice)
    })
  }

  const toggleReset = (status) => {
    if (!bulkChoice) {
      const key = `${selectedSubMenuKey}-${selectedPurpose?.isNegative}-${activeTab}`
      if (selectedPurpose.type !== "CUSTOM") {
        setShowReset({ ...showReset, [key]: status })
      }
    } else {
      setBulkChoice(null)
    }
  }

  const collapse = (e, selKey) => {
    e.stopPropagation(); 
    setOpenKeys([]); 
    handleMenuClick(selKey,activeTab)
    setShowMessageTone(activeTab === "2");
  }

  const getData = (data) => {
    const engLishTranslation = data?.find(d => d.name === "English")?.translation
    if (engLishTranslation?.trim() === "") {
      return {
        showOtherLanguages: false,
        filteredData: data.filter(d => d.name === "English")
      }
    } else {
      return {
        showOtherLanguages: true,
        filteredData: data
      }   
    }
  }

  const selectedTone = messageTones.find(m => m.value === selectedPurpose?.isNegative).label;
  const resetValues = showReset

  let selectedLangWithoutTrans = dropdownLanguages.filter(c => !uniqueTranslationLanguages.includes(c.code.toLowerCase())).filter(l => selectedLang.includes(l.code)).map(l => l.language)?.toJS()?.toString()
  
  const alertForEmptyLanguages = <Alert className="top-margin" type="warning" showIcon message={<>We do not provide default translations for {selectedLangWithoutTrans}. Please make sure to add translations or else it will default to English language.</>} />

  showIdentificationList ? tabs.push({ key: 4, title: "Identification List", infoText: "This text in the message allows the user to display a link to the Identification List giving them access to all the downstream participants" }) : tabs.splice(3, 1);
  const { showOtherLanguages, filteredData }  = getData(data); 
  return (
    <>
      {props.changesDetectedWarning}
      {!props.readOnly && (
      <>
        <div className="step-header">
          <h4>Language & Translation Settings</h4>
        </div>
        <div className="step-breif">
          On the previous screen you picked the privacy choices that you wish to present to your users. In this section you will be able to change the language used to communicate those privacy choices to the user and add translations.
        </div>
      </>)}
      {props.showError ? <div style={{ color: 'red' }}>Fill missing choice text</div> : null}
      <div className={`step-section ${props.readOnly ? "no-border" : "padding-md"} ${props.showError ? "validation-error " : null} `}>
          <div className={`supported-language ${props.readOnly ? "no-padding" : null}`}>
            <Space direction="vertical">
            {props.readOnly ? null : (
              <div>
                <Typography.Text strong>Supported Language(s)</Typography.Text>
              </div>
              )}
            {!props.readOnly ? (
              <SelectDropdown
                  options={languageOptions}
                  onChange={(value) =>
                    handleSelectedLanguage(value,activeTab)
                  }
                  value={selectedLang}
                  selectAll
                  multiple
                  entityName="Languages"
                  showSearch
                  directUpdate
                />
              ) : (
                <div className="selected-language-container">
                  {languageNames.map((lang) => (
                    <Tag>{lang}</Tag>
                  ))}
                </div>
              )}
            </Space>
            {selectedLangWithoutTrans ? alertForEmptyLanguages : null}
            <Flex justify="space-between" align="center">
              <Typography.Text strong>Privacy Choice Message Translations</Typography.Text>
              <Space>
                {!props.readOnly && (<Button onClick={() => setShowBulkUpload(true)}  icon={<UploadOutlined/>}>Bulk Upload</Button>)}
                <Button onClick={() => setShowPreview(true)} icon={<EyeOutlined/>}> Preview</Button>
              </Space>
            </Flex>
            <Typography.Text type="secondary">
              For each of the selected privacy choices, we have the default copy for the choice text and description, and we provide language support for all the languages selected above. You can choose to change the default copy and add translations manually or use the “Bulk Upload Translations” tool for each language you wish to support. At any point, you can see an example of how this description and text will be reflected in the message by clicking on the “Preview” option.
            </Typography.Text>
          </div>
          <Tabs
            className="language-translation-tabs"
            defaultActiveKey={1}
            activeKey={activeTab}
            onChange={onChangeTab}>
            {tabs.map((t => {
              const purposes = getMenuItems(t.key)
              const defaultSelectedPurpose = props.usPrivacyRegulation.categories[0]
              const selectedKey = getChoiceId(defaultSelectedPurpose);
              const isPrivacyLinkPresent = checkPrivacyLink(regulationCategories)
              const showSection = (isPrivacyLinkPresent === false && activeTab === "3") ? false : true;
              const showCustomText = (activeTab === "1" || activeTab === "2");
              const legalBasis = selectedPurpose?.defaultLegalBasis === "OPT-OUT" ? "opt-out" : "opt-in";
              const toogleState = selectedPurpose?.defaultLegalBasis === "OPT-OUT" ? "ON": "OFF"
              const toggleTooltipBasedOnTone = selectedPurpose?.defaultLegalBasis === "CONSENT" ? selectedPurpose?.isNegative : !selectedPurpose?.isNegative // for message sentiment tooltip
              return (
                <TabPane
                  icon={renderInfoTip(t.infoText, "warning", 0)}
                  tab={t.title}
                  key={t.key}
                >
                  <div className="translation-container">
                    {showSection && (<div className="menu">
                      <div className="menu-title">Privacy Choices</div>
                      <hr />
                      <Menu
                        defaultSelectedKeys={[selectedKey]}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        inlineCollapsed={false}
                        selectedKeys={selectedSubMenuKey}
                        onClick={({ key }) => handleMenuClick(key, activeTab)}
                        expandIcon={(props) => {
                          return props.isOpen ? <MinusOutlined className="expand-icon" onClick={(e) =>{e.stopPropagation(); collapse(e,selectedKey)}} /> : <PlusOutlined className="expand-icon" onClick={(e) => { e.stopPropagation(); setOpenKeys([props.eventKey]) }} />
                        }}
                        openKeys={openKeys}
                      >
                        {purposes}
                      </Menu>
                    </div>)}
                    {showSection && (<div className="content">
                      <TranslationTable
                        selLanguages={selectedLang}
                        dataSource={filteredData}
                        selectedSubMenuKey={selectedSubMenuKey}
                        readOnly={props.readOnly || (activeTab === "3" || activeTab === "4") || false}
                        handleTranslationChanges={handleTranslationChanges}
                        resetData={resetData}
                        showResetStatus={resetValues}
                        selectedPurpose={selectedPurpose}
                        showMessageTone={showMessageTone}
                        toggleReset={toggleReset}
                        activeTab={activeTab}
                        resetKey={`${selectedSubMenuKey}-${selectedPurpose?.isNegative}-${activeTab}`}
                        isStateFlow={props.isStateFlow}
                      />
                      {(!showOtherLanguages && selectedPurpose.type === "CUSTOM") && (
                        CustomEnglishInfoTip
                      )}
                      {(showMessageTone && selectedPurpose.type !== "CUSTOM" && !props.isStateFlow && selectedPurpose.defaultLegalBasis) ? (<div className="footer">
                        <div className="message-tone-container">
                          <span>Message Sentiment: &nbsp;</span>
                          {!props.readOnly && (<SelectDropdown
                            options={messageTones}
                            onChange={(value) =>
                              handleMessageTone(value[0])
                            }
                            value={[selectedPurpose?.isNegative]}
                            entityName="Languages"
                            directUpdate
                          />)}
                          {props.readOnly && (
                            <Tag className="language-tags">{selectedTone}</Tag>
                          )}
                          {renderInfoTip(MESSAGE_SENTIMENT_TOOLTIP(selectedPurpose?.isNegative, toggleTooltipBasedOnTone, selectedPurpose?.privacyChoice))}
                        </div>
                      </div>) : null}
                      {!props.isStateFlow && selectedPurpose.type === "CUSTOM" && showCustomText && selectedPurpose.defaultLegalBasis ? (
                          <div className="default-tone-container">
                            <span>{renderInfoTip(MESSAGE_SENTIMENT_TOOLTIP(selectedPurpose?.isNegative, toggleTooltipBasedOnTone, selectedPurpose?.privacyChoice))}
                            <b>Note:</b> the default toggle state for an {legalBasis} custom choice is {toogleState}
                            </span>
                          </div>
                      ) : null}
                    </div>)}
                    {!showSection && (
                      <div className="empty-section">
                        You need to add Privacy Policy Section Link in the previous step to reflect it here
                      </div>)}
                  </div>
                </TabPane>
              )
            }))}
          </Tabs>
      </div>
      {showBulkUpload && (
        <BulkUploadModal
          closeModal={() => setShowBulkUpload(false)}
          selectedLang={selectedLang}
          languageOptions={languageOptions}
          purposeOptions={purposeOptions}
          descriptionOptions={descriptionOptions}
          regulationCategories={regulationCategories}
          uploadTranslations={uploadTranslations}
          activeTab={activeTab}
        />
      )}
      {showPreview && (
        <PreviewModal
          closeModal={() => setShowPreview(false)}
          regulationCategories={regulationCategories}
          selectedLang={selectedLang}
          isIdentificationListVisible={showIdentificationList}
          isStateFlow={props.isStateFlow}
        />
      )}
    </>
  )
}

export default LanguageTranslations;