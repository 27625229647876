import React, { useState } from 'react';
import { COUNTRY_LANGUAGES } from '../../../../../../constants';
import { Select, Space, Typography, Modal, Button, Switch, Row, Col, theme } from 'antd';

const PreviewModal = (props) => {
  const { closeModal, regulationCategories, selectedLang, isIdentificationListVisible, isStateFlow } = props;
  const flatCats = regulationCategories.map((cat) => cat.categories?.length ? cat.categories : cat).flat();

  const [currentTab, setCurrentTab] = useState('descriptions');
  const [isActive, setIsActive] = useState(props.isFirstActive);
  const [selectedLanguage, setLanguage] = useState("EN")
  const [expanded, setExpanded] = useState(false);

  let languageOptions = COUNTRY_LANGUAGES.toJS().filter((c => selectedLang?.includes(c.code))).map((l, i) => ({
    label: l.language,
    value: l.code,
  }));

  const changeTab = (value) => {
    setCurrentTab(value)
    setIsActive(!isActive)
  }

  const handleSelectedLanguage = (value) => {
    setLanguage(value)
  }

  const getPrivacyChoiceText = (category,isSubCat) => {
    const sentiment = category.isNegative;
    let status = null;
    if (isStateFlow) {
      status = category?.isNegative;
    } else {
      status =
        category?.defaultLegalBasis === "CONSENT"
          ? category?.isNegative
          : !category?.isNegative;
    }
    const translations = (sentiment) ? category.negativeTranslations.names : category.translations.names;
    const language = selectedLanguage.toLowerCase()
    const translationLanguage = translations[language]?.trim();
    const englishTranslation = translations["en"]?.trim();
    const privacyChoiceText = (translationLanguage === "No Translations found" || translationLanguage === "") ? englishTranslation : translationLanguage
    if (privacyChoiceText) {
      return (
        <div className={`bullet-container ${currentTab === "names" ? 'selected' : 'not-selected'}`}>
          {!isSubCat && (<Switch checked={status} />)}
          <div style={{ flex: 1 }}>{privacyChoiceText}</div>
        </div>
      )
    }
  }

  const convertTextToLink = (text,link) => {
    // Use a regular expression to find and replace text in square brackets with links
    const updatedText = text.replace(/\[(.*?)\]/g, (match, linkText) => {
      // Create the link using the matched text
      return `<a target="_blank" href="${link}">${linkText}</a>`;
    });

    return { __html: updatedText };
  }

  const getPrivacyChoiceLink = (category) => {
    if (category.privacyPolicySectionLink) {
      const privacyPolicyTranslations = category.translations?.privacyPolicySectionText;
      const language = selectedLanguage.toLowerCase()
      const englishTranslation = privacyPolicyTranslations["en"]?.trim();
      const privacyPolicyTranslation = (privacyPolicyTranslations[language] === "No Translations found" || privacyPolicyTranslations[language] === "") ? englishTranslation : privacyPolicyTranslations[language] 
      if (privacyPolicyTranslation) {
        const updatedText = convertTextToLink(privacyPolicyTranslation, category.privacyPolicySectionLink);
        return (
          <div dangerouslySetInnerHTML={updatedText}></div>
        )
      }
    }
  }

  const getIdentificationList = () => {
    const systemCategories = flatCats.filter(cat => (cat.defaultLegalBasis === "OPT-OUT" || cat.defaultLegalBasis === "OPT_OUT") && cat.type !== "CUSTOM");
    const language = selectedLanguage.toLowerCase()
    const isLastIndex = systemCategories.length - 1;
    const identificationList = systemCategories[isLastIndex]?.translations?.identificationList;
    const showIdentificationList = (identificationList) ? ((identificationList[language] === "No Translations found" || identificationList[language] === "") ? identificationList["en"] : identificationList[language]): (systemCategories[isLastIndex]?.identificationList)? systemCategories[isLastIndex]?.identificationList: null
    if (showIdentificationList && isIdentificationListVisible) {
      return (
        <div dangerouslySetInnerHTML={{ __html: showIdentificationList }}></div>
      )
    }
  }

  const getDescriptionText = (category) => {
    const sentiment = category.isNegative;
    const translations = (sentiment) ? category.negativeTranslations.descriptions : category.translations.descriptions;
    const language = selectedLanguage.toLowerCase()
    const translationLanguage = translations[language]?.trim();
    const englishTranslation = translations["en"]?.trim();
    const showDescription = (translationLanguage === "No Translations found" || translationLanguage === "") ? (englishTranslation !== "" ? englishTranslation : null) : translationLanguage
    if (showDescription) {
      return(<div>{showDescription}</div>)
    }
  }

  const regulationSortedData = flatCats?.sort((a, b) => {
    // If defaultLegalBasis is the same, maintain the original order
    if (a.defaultLegalBasis === b.defaultLegalBasis) {
      return 0;
    }
    // 'OPT_In' comes before 'OPT_Out'
    if (a.defaultLegalBasis === 'CONSENT') {
      return -1;
    }
    // 'OPT_Out' comes after 'OPT_In'
    if (a.defaultLegalBasis === 'OPT-OUT') {
      return 1;
    }
  });

  const highlightOptions = [
    {label: "Description", value: "descriptions"},
    {label: "Choice text", value: "names"},
  ]
  if(!isStateFlow) {
    highlightOptions.push({label: "Privacy Policy Section Link", value: "privacyPolicySectionText"})
  }
  if(isIdentificationListVisible) {
    highlightOptions.push({label: "Identification list", value: "identificationList"})
  }

  const { token } = theme.useToken();
  const identificationList = getIdentificationList();

  return (
    <Modal
      wrapClassName={`preview-modal`}
      open={true}
      visible={true}
      footer={(
        <Button type='primary' onClick={closeModal}>Close</Button>
      )}
      onCancel={closeModal}
      title={"Preview"}
      width={720}
    >
      <Row gutter={[0,token.margin]}>
        <Col>
          <Typography.Paragraph
            type='secondary'
            ellipsis={{
              rows: 1,
              expandable: 'collapsible',
              expanded,
              symbol: ((expanded) => expanded ? "View Less" : "View More"),
              onExpand: (_, info) => setExpanded(info.expanded),
            }}
          >
            This is a preview of how your message will look for the configuration you have set up so far in the regulation setting. You can select one of the options below to see the corresponding highlighted information in the message preview and change the language in the dropdown field at the bottom to visualize the message in different languages.
          </Typography.Paragraph>
        </Col>
        <Col>
          <div className="section-tabs">
            <Space>
              <Typography.Text>Language</Typography.Text>
              <Select
                options={languageOptions}
                onChange={(value) =>
                  handleSelectedLanguage(value)
                }
                value={selectedLanguage}
              />
            </Space>
            <Space>
              <Typography.Text>Highlight</Typography.Text>
              <Select
                value={currentTab}
                options={highlightOptions}
                onChange={changeTab}
              />
            </Space>
          </div>
        </Col>
        <Col>
          <Row className="msg-container" gutter={[0,token.marginSM]}>
            {
              regulationSortedData.map(reg => {
                const subCategories = reg.categories;
                const description = getDescriptionText(reg);
                const privacyLink = getPrivacyChoiceLink(reg);

                return (
                  <Col>
                    <Space direction='vertical' size={token.marginXXS}>
                      {description && (<div className={`desc-container ${currentTab === "descriptions" ? 'selected' : 'not-selected'}`}>
                        {description}
                      </div>)}
                      <div className={`privacy-container`}>
                        {subCategories.length > 0 && (
                          <ul>
                            {reg.categories.map(c => {
                              return getPrivacyChoiceText(c,true)
                            })}
                          </ul>
                        )}
                        {getPrivacyChoiceText(reg,false)}
                      </div>
                      {privacyLink ? (
                        <div className={`privacy-link ${currentTab === "privacyPolicySectionText" ? 'selected' : 'not-selected'}`}>
                          {privacyLink}
                        </div>
                      ) : null}
                    </Space>
                  </Col>
                )
              })
            }
            {identificationList ? (
              <Col className={`privacy-link ${currentTab === "identificationList" ? 'selected' : 'not-selected'}`}>
              {identificationList}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default PreviewModal;