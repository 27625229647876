import { EditOutlined, LeftOutlined, MinusSquareOutlined, RightOutlined, SafetyOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Input, Modal, Row, Select, Space, Spin, Table, Tag, Tooltip, Typography, theme } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { GEO_LIST_TREE_STRUCTURE, region_title_index_map } from "../../../../../../constants";
import BulkRegionMappingModal from "./BulkRegionMappingModal";
import { IAB_CHOICES_SYSTEM_IDS, LEGALBASIS_OPTIONS, renderInfoTip, ACTIVE_STATE_LAW_CODES, StateCodeRender } from "../../../helper";
import { useDebouncedSearch } from "../../../../../../hooks";
import RespectGpcModal from "./RespectGpcModal";
import { useSelector } from "react-redux";

const {Title, Text, Paragraph} = Typography;

const RegionMapping = (props) => {
  const [categories, setCategories] = useState(props.usPrivacyRegulation.categories ?? []);
  const [privacySignalConfigByGeo, setPrivacySignalConfigByGeo] = useState(props.usPrivacyRegulation.configByGeo);
  const [regionCategoryMapping, setRegionCategoryMapping] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openRespectGpcModal, setOpenRespectGpcModal] = useState(false);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(true);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']))
  const systemPurposesPending = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'pending']));
  const stateStringRegions = useSelector(state => state.usPrivacyReducerState.getIn(['stateStringRegions', 'value']))
  const { token } = theme.useToken();
  const [modal, contextHolder] = Modal.useModal();
  const usnatRegionsToUpdate = useMemo(() => regionCategoryMapping.reduce((acc,curr) => {
    if(!acc?.includes(curr.region?.split("_",1)[0]) && curr.privacySignal === "usnat") {
      acc.push(curr.region?.split("_",1)[0])
    }
    return acc;
  },[]),[regionCategoryMapping]);

  useEffect(() => {
    props.usPrivacyRegulation.categories = categories;
    props.usPrivacyRegulation.configByGeo = privacySignalConfigByGeo
  }, [categories, privacySignalConfigByGeo])

  async function handleLegalBasisChange(categoryId, regionCode, legalBasisValue) {
    if(privacySignalConfigByGeo[regionCode]?.legislation === "usnat" && !props.isNoFrameworkFlow) {
      const confirmation = await modal.confirm({
        title:"Consent Update",
        content: "Since the national string has only one string, we will be applying this consent change to all the regions with National Signal for this privacy choice. Are you sure you want to go ahead with this change?",
        okText: "Apply",
        width: 500,
      })
      if(confirmation) {
        setCategories((categories) => categories.map((cat) => {
          if(cat.id === categoryId) {
            usnatRegionsToUpdate.forEach((region) => {
              cat.configByGeo[region] = {defaultLegalBasis: legalBasisValue};
            })
          } 
          return cat;
        }))
      }
    } else {
      setCategories((categories) =>categories.map((cat) => {
        if(cat.id === categoryId) {
          cat.configByGeo[regionCode] = {defaultLegalBasis: legalBasisValue};
        } 
        return cat;
      }))
    }
  }

  async function handlePrivacySignalChange(regionCode, privacySignalValue) {
    if( privacySignalValue === "usnat" && !props.isNoFrameworkFlow) {
      const confirmation = await modal.confirm({
        title:"Consent Update",
        content: "Switching to the national string for this region will update the privacy choice settings to match the current configuration for other regions using the national string. If no other region is using the national string in your vendor list, the IAB default settings will apply.",
        okText: "Apply",
        width: 500,
      })
      if(confirmation) {
        setPrivacySignalConfigByGeo(prevConfig => {
          const newConfig = {...prevConfig}; // Create a new object with spread
          newConfig[regionCode] = {legislation: privacySignalValue}
          return newConfig; // Return the new object
        });
        setCategories((categories) => categories.map((cat) => {  
          if(cat.configByGeo[regionCode]) {
            // cat.configByGeo[regionCode?.split("_", 1)[0]] = cat.configByGeo[regionCode];
            delete cat.configByGeo[regionCode];
          }
          return cat;
        }))
      }
    } else {
      setPrivacySignalConfigByGeo(prevConfig => {
        const newConfig = {...prevConfig}; // Create a new object with spread
        newConfig[regionCode] = {legislation: privacySignalValue}
        return newConfig; // Return the new object
      });
      setCategories((categories) => categories.map((cat) => {
        if(cat.configByGeo[regionCode.split("_",1)[0]]) {
          cat.configByGeo[regionCode] = cat.configByGeo[regionCode.split("_",1)[0]]
          // delete cat.configByGeo[regionCode.split("_",1)[0]];
        } 
        return cat;
      }))
    }
  }

  const columns = [
    {
      title: "Region",
      fixed: "left",
      dataIndex: "region",
      width: 200,
      sorter: (a,b) => a.title?.localeCompare(b.title),
      filters: props.isNoFrameworkFlow ? null : [
        {
          text: <Typography.Text><img src="/svg_icons/trademark-s.svg" style={{marginBottom: 2}}/> - Support state string {renderInfoTip("A state which has a string supported by the GPP")}</Typography.Text>,
          value: "state-string"
        },
        {
          text: <Typography.Text><img src="/svg_icons/trademark-a.svg" style={{marginBottom: 2}}/> - Active State Law {renderInfoTip(" A state that has an effective legislation")}</Typography.Text>,
          value: "active-law"
        },
        {
          text: "All other regions",
          value: "other"
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        const isActiveStateLaw = ACTIVE_STATE_LAW_CODES.includes(record.region.replace("US_", "STATE_"));
        const isStateString = stateStringRegions[record?.region];
        switch(value) {
          case "active-law":
            return isActiveStateLaw;
          case "state-string":
            return isStateString;
          default:
            return (!isActiveStateLaw && !isStateString)
        }
      },
      render: (stateCode, record) => {
        return (
          <Flex gap={token.marginSM} align="center">
            <Text>{record.title}</Text>
            {props.isNoFrameworkFlow ? null : <StateCodeRender stateCode={stateCode.replace("US_", "STATE_")}/>}
          </Flex>
        );
      },
    }
  ]
  if(!props.isNoFrameworkFlow) {
    columns.push(
      {
        title: <>Privacy Signal {renderInfoTip(<>National Signal: To use the us_nat signal in compliance with IAB guidelines ensure you have signed the MSPA.<br/>State Signal: Before choosing to send a state signal, verify that the vendors you work with, support it.</>)}</>,
        fixed: "left",
        dataIndex: "privacySignal",
        width: 200,
        render: (value, record) => {
          let options = [{label: "National", value: "usnat"}];
          if(stateStringRegions[record?.region]) {
            options.push({label: "State", value: "state"})
          }
          return (
            <Select
              value={value}
              defaultValue={"usnat"}
              placeholder="Select"
              options={options}
              disabled={props.readOnly}
              onChange={(value) => handlePrivacySignalChange(record.region, value)}
              style={{minWidth: 150}}
            />
          )
        }
      }
    )
  }

  categories.sort((a,b) => a.purposeRef?.systemId - b.purposeRef?.systemId).forEach((cat) => {
    const isIabCat = cat.type === "SYSTEM_PURPOSE"
    let options = LEGALBASIS_OPTIONS;
    if(!isIabCat) {
      options = [...options, {label: "Disclosure Only", value: "DISCLOSURE_ONLY"}]
    } 
    columns.push({
      title: (
        <Space direction="vertical">
          <Paragraph style={{maxWidth: 150}} ellipsis={{tooltip:{title: cat.privacyChoice ?? cat.name}, rows: 2}}>{cat.privacyChoice ?? cat.name}</Paragraph>
          <Tag color={isIabCat ? "orange" : "magenta"}> {isIabCat ? props.isNoFrameworkFlow ? "Standard" : "IAB" : "Custom"}</Tag>
        </Space>
      ),
      dataIndex: ["legalBasis", cat.id],
      width: 200,
      sorter: (a,b) => {
        const legalBasisOrder = options.reduce((acc, option, index) => {
          acc[option.value] = index;
          return acc;
        }, {});
        const aOrder = legalBasisOrder[a.legalBasis[cat.id]] ?? 3; // 3 is the index for not_applicable
        const bOrder = legalBasisOrder[b.legalBasis[cat.id]] ?? 3;
        return aOrder - bOrder;
      },
      showSorterTooltip: {target: 'sorter-icon'},
      render: (value, record) => {
        const iabPurposeOriginal = systemPurposes.find((purpose) => purpose.purposeRef.id === cat.purposeRef?.id)
        const disabledIabChoice = false ;//!props.isNoFrameworkFlow && iabPurposeOriginal && !Object.keys(iabPurposeOriginal.configByGeo).includes(record.region)
        return (
          <Tooltip
            title={disabledIabChoice ? "Changing this not applicable in this region" : null}
          >
            <Select
              value={value ?? "NOT_APPLICABLE"}
              defaultValue={!Boolean(value) ? "NOT_APPLICABLE" : null}
              placeholder="Select"
              options={options}
              disabled={disabledIabChoice || props.readOnly}
              onChange={(value) => handleLegalBasisChange(cat.id, record.region, value)}
              style={{minWidth: 150}}
            />
          </Tooltip>
        )
      }
    })
  })
  
  useEffect(() => {
    let selectedRegionCodes = []
    GEO_LIST_TREE_STRUCTURE.forEach((option) => {
      if (props.usPrivacyRegulation.appliesGeos.includes(option.key)) {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedRegionCodes = selectedRegionCodes.concat(filteredChildCodes);
      } else {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => props.usPrivacyRegulation.appliesGeos.includes(code) && !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedRegionCodes = selectedRegionCodes.concat(filteredChildCodes)
      }
    });
    let regionCategoryMapping = selectedRegionCodes.map((code) => {
      let codeString = code?.replace("STATE_", "US_")
      return ({region: codeString, key: codeString, title: region_title_index_map[code], legalBasis: {}, privacySignal: privacySignalConfigByGeo[codeString]?.legislation ?? "usnat"})
    });
    categories.forEach((cat) => {
      if(cat.configByGeo) {
        regionCategoryMapping = regionCategoryMapping.map((data) => {
          let defLegBasisRecord = cat.configByGeo[data.region]
          if(data.privacySignal === "usnat" && !props.isNoFrameworkFlow) {
            defLegBasisRecord = cat.configByGeo[data.region?.split("_",1)[0]]
          }
          if(defLegBasisRecord) {
            data["legalBasis"][cat.id] = defLegBasisRecord.defaultLegalBasis
          }
          return data;
        })
      } 
    })
    setRegionCategoryMapping(regionCategoryMapping);
  }, [categories, privacySignalConfigByGeo]);

  const rowSelection = {
    selectedRowKeys,
    onSelect : (record, selected, selectedRows, nativeEvent) => {
      if(selected) {
        setSelectedRowKeys((selectedKeys) => [... new Set(selectedKeys.concat(record.region))])
      } else {
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter(key => key !== record.region))
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if(selected){
        setSelectedRowKeys((selectedKeys) => [...new Set(selectedKeys.concat(regionCategoryMapping.map(v => v.region)))]);
      }else{
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter((key) => !regionCategoryMapping.map(v => v.region).includes(key)));
      }
    },
  }

  function handleClearSelection() {
    setSelectedRowKeys([]);
  }

  function handleBulkEdit() {
    setOpenBulkModal(true);
  }

  function handleGpcEdit() {
    setOpenRespectGpcModal(true);
  }

  const scrollTable = (scrollBy) => {
    const tableBody = document.querySelector('.usnat-region-mapping .ant-table-content');
    if (tableBody) {
      tableBody.scrollTo({
        left: tableBody.scrollLeft + scrollBy,
        behavior: 'smooth' // Enables smooth scrolling
      });
    }
  };

  const updateButtonState = () => {
    const tableBody = document.querySelector('.usnat-region-mapping .ant-table-content');
    if (tableBody) {
      const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

      setIsLeftDisabled(tableBody.scrollLeft <= 0);
      setIsRightDisabled(tableBody.scrollLeft >= maxScrollLeft - 0);
    }
  };

  useEffect(() => {
    const tableBody = document.querySelector('.usnat-region-mapping .ant-table-content');
    if (tableBody) {
      tableBody.addEventListener('scroll', updateButtonState);
      // Initial check to set button states
      updateButtonState();
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', updateButtonState);
      }
    };
  }, []);

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  let dataSource = regionCategoryMapping
  if(searchValue.trim().length) {
    dataSource = regionCategoryMapping?.filter(record => record.title?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1)
  }

  const gpcButton = categories.some((cat) => cat.purposeRef?.systemId === IAB_CHOICES_SYSTEM_IDS.saleAndSharing) ?  <Button type="primary" icon={<SafetyOutlined/>} onClick={handleGpcEdit}>GPC</Button> : null;

  return systemPurposesPending ? <Spin/> :  (
    <>
      {props.changesDetectedWarning}
      <Row gutter={[0, token.margin]}>
        <Col span={24}>
          {!props.readOnly ? (
            <Space direction="vertical" size={token.marginXXS}>
              <Title level={4}>Region Mapping</Title>
              <Text>
                {props.isNoFrameworkFlow
                  ? "We have configured the default consent options for privacy choices on a per state basis, based on the latest legislation. You can make modifications using the drop-down menus in the matrix below."
                  : "We have configured the default consent options for privacy choices on a per state basis, based on the state-level technical specifications that were developed by IAB's Tech Lab. You can make modifications using the drop-down menus in the matrix below."}
              </Text>
            </Space>
          ) : null}
        </Col>
        <Col span={8}>
          <Input.Search
            placeholder="search"
            type="search"
            onChange={({ target: { value } }) => {
              debouncedChangeHandler(value);
            }}
          />
        </Col>
        <Col span={8} offset={8}>
          <Flex align='center' justify="flex-end">
            <Space size={token.margin}>
              {selectedRowKeys.length 
              ? (
                <>
                  <Button type="link" icon={<MinusSquareOutlined/>} onClick={handleClearSelection} disabled={props.readOnly}> Clear Selection</Button>
                  <Button type="primary" icon={<EditOutlined/>} onClick={handleBulkEdit} disabled={!selectedRowKeys.length || props.readOnly}> Bulk Edit ({selectedRowKeys.length})</Button>
                </>
              ) : gpcButton}
            </Space>
            {Boolean(gpcButton) || selectedRowKeys.length ? <Divider type="vertical" /> : null}
            <Space size={token.margin}>
              <Button  disabled={isLeftDisabled} icon={<LeftOutlined/>} onClick={() => scrollTable(-1000)}></Button>
              <Button  disabled={isRightDisabled} icon={<RightOutlined/>} onClick={() => scrollTable(1000)}></Button>
            </Space>
          </Flex>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataSource}
            className="usnat-region-mapping"
            rowSelection={!props.readOnly ? rowSelection : null}
            scroll={{ x: 1000}}
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => `Total ${total} items`,
              size:'small',
              defaultPageSize: props.readOnly ? 6 : 7,
              showSizeChanger: true,
              pageSizeOptions: ['7', '10', '20', '50'],
            }}
          />
        </Col>
      </Row>
      {openBulkModal ? (
        <BulkRegionMappingModal
          selectedRegions={regionCategoryMapping.filter(({region}) => selectedRowKeys.includes(region))}
          usnatRegionsToUpdate={usnatRegionsToUpdate}
          categories={categories}
          privacySignalConfigByGeo={privacySignalConfigByGeo}
          setCategories={setCategories}
          setPrivacySignalConfigByGeo={setPrivacySignalConfigByGeo}
          openModal={openBulkModal}
          closeModal={() => setOpenBulkModal(false)}
          isNoFrameworkFlow={props.isNoFrameworkFlow}
          stateStringRegions={stateStringRegions}
        />
      ) : null}
      {openRespectGpcModal ? (
        <RespectGpcModal
          respectGpcCategory={categories.find((cat) => cat.purposeRef?.systemId === IAB_CHOICES_SYSTEM_IDS.saleAndSharing) ?? {respectGPC: null}}
          regionsInVendorList={regionCategoryMapping.map((opt) => opt.region)}
          setCategories={setCategories}
          openModal={openRespectGpcModal}
          closeModal={() => setOpenRespectGpcModal(false)}
          readOnly={props.readOnly}
          isNoFrameworkFlow={props.isNoFrameworkFlow}
        />
      ) : null}
      {contextHolder}
    </>
  );
}

export default RegionMapping;