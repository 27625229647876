import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Flex, Input, Row, Dropdown, Space, Table, Tooltip, Typography, theme, Menu, Spin, Modal, Tag} from "antd";
import { EditOutlined, MinusSquareOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { ConfirmationWrapper } from "../../../../../consent/helper";
import { SystemPurpose } from "../../../../../../records/us_privacy_regulation_records";
import { useDebouncedSearch } from "../../../../../../hooks";
import AddIabChoicesModal from "./AddIabChoicesModal";
import AddCustomChoicesModal from "./AddCustomChoices";
import { GEO_LIST_TREE_STRUCTURE } from "../../../../../../constants";
import { getStepsDataFieldMap, updateStepsDataFunction } from "../../../../../common/SequentialNavigationWizard/SequentialLeftNav";
import { evaluateRequriedFieldsFromStep, renderInfoTip } from "../../../helper";
import { message } from "../../../../../../styleguide";

const { Title, Text, Paragraph } = Typography;

const ChoiceSelection = (props) => {
  const { token } = theme.useToken();
  const [modal, contextHolder] = Modal.useModal();

  const [regulationCategories, setRegulationCategories] = useState(props.usPrivacyRegulation.categories ?? []);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']));
  const systemPurposesPending = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'pending']));
  const systemPurposesJS = props.regulationCategoryOptionsCache?.filter(cat => cat.type == 'SYSTEM_PURPOSE').toJS() ?? [];
  const [systemPurposesWithValidConfigGeos, setSystemPurposesWithValidConfigGeos] = useState([]);
  const [cancelBtnTextAndOpenIabModal, setCancelBtnTextAndOpenIabModal] = useState(null)
  const [openModalCustom, setOpenModalCustom] = useState(false)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [searchValue, setSearchValue] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const stepsStatusMap = getStepsDataFieldMap(props.stepsData, 'status')

  const selectedIabPurposes = regulationCategories?.filter(pur => pur.type === "SYSTEM_PURPOSE").map(pur => pur.purposeRef.id)
  const filteredSystemPurpose = systemPurposesWithValidConfigGeos.filter(pur => !selectedIabPurposes.includes(pur.id));

  const checkForChangesDetectedErrorState = () => {
    if(props.readOnly) return;

    let updatedStepsData = props.stepsData;
    if(stepsStatusMap.get('language_translations') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'language_translations', 'warning', true)
    }
    if(stepsStatusMap.get('opt_in_opt_out') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'opt_in_opt_out', 'warning', true)
    }
    if(stepsStatusMap.get('vendor_management') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'vendor_management', 'warning', true)
    }
    if(stepsStatusMap.get('region_mapping') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'region_mapping', 'warning', true)
    }
    props.setStepsData((asdf) => updatedStepsData);
  }

  useEffect(() => {
    if(props.readOnly) return;
    props.usPrivacyRegulation.categories = regulationCategories;
    props.resetValidationError();
    
    const errorInLanguageTranslations = getStepsDataFieldMap(props.stepsData, 'error').get('language_translations');
    if(errorInLanguageTranslations && evaluateRequriedFieldsFromStep('language_translations', props.usPrivacyRegulation, [])){
      let updatedStepsData = updateStepsDataFunction(props.stepsData, 'language_translations', 'error', false)
      props.setStepsData((data) => updateStepsDataFunction(updatedStepsData, 'language_translations', 'status', 'finish')) 
    }
  }, [regulationCategories])

  useEffect(() => {
    if(props.readOnly || stepsStatusMap.get("choice_selection") === "finish") return;
    const timer = setTimeout(() => {
      setCancelBtnTextAndOpenIabModal("Skip for now");
    }, 1500);
    return () => clearTimeout(timer);
  }, []); 

  useEffect(() => {
    let selectedRegionCodes = []
    GEO_LIST_TREE_STRUCTURE.forEach((option) => {
      if (props.usPrivacyRegulation.appliesGeos.includes(option.key)) {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedRegionCodes = selectedRegionCodes.concat(filteredChildCodes);
      } else {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => props.usPrivacyRegulation.appliesGeos.includes(code) && !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedRegionCodes = selectedRegionCodes.concat(filteredChildCodes)
      }
    });
    selectedRegionCodes = selectedRegionCodes.map((code) => code?.replace("STATE_", "US_"));
    const data = systemPurposesJS.map((cat) => {
      const configByGeo = Object.fromEntries(
        Object.entries(cat.configByGeo).filter(([key, value]) => selectedRegionCodes.includes(key))
      );
      return ({id: cat.purposeRef.id, privacyChoice: cat.privacyChoice, configByGeo, systemId: cat.purposeRef ? cat.purposeRef.systemId : null})
    });
    setSystemPurposesWithValidConfigGeos(data);
  }, [systemPurposes]);

  const handleModalVisibility = (type) => {
    if (type === 'iab') {
      setCancelBtnTextAndOpenIabModal("Cancel")
    } else if (type === 'custom') {
      setOpenModalCustom(true)
      setCurrentRecord(null);
    }
  }

  const handleCancel = (type) => {
    if (type === 'iab') {
      setCancelBtnTextAndOpenIabModal(null)
    } else if (type === 'custom') {
      setOpenModalCustom(false);
      setCurrentRecord(null);
    }
  }

  const addIabChoice = (selectedRows) => {
    let choiceData = systemPurposesJS.filter(purpose => selectedRows.includes(purpose.purposeRef?.id));
    choiceData = choiceData.map((pur) => {
      // const configByGeo = systemPurposesWithValidConfigGeos.find((p) => p.id === pur.purposeRef?.id)?.configByGeo;
      const legislationConfigs = props.usPrivacyRegulation.configByGeo;
      let updatedConfigByGeo = Object.fromEntries(Object.entries(legislationConfigs).map(([key,value]) => {
        let newKey = key;
        if(value?.legislation === "usnat" && !props.isNoFrameworkFlow) {
          newKey = key?.split('_',1)[0];
          value = {defaultLegalBasis: pur.defaultLegalBasis ?? "NOT_APPLICABLE"};
        } else {
          value = pur.configByGeo[key] ?? {defaultLegalBasis : "NOT_APPLICABLE"};
        }
        return [newKey,value]
      }))
      pur.configByGeo = updatedConfigByGeo;
      if (pur.identificationList) pur.identificationList = '';
      return pur;
    });
    setRegulationCategories((data) => [...data,...choiceData])
    setCancelBtnTextAndOpenIabModal(null)
    checkForChangesDetectedErrorState();
  }

  function generateRandom10DigitNumber() {
    const min = 1000000000; // Minimum 10-digit number
    const max = 9999999999; // Maximum 10-digit number
    // Generate a random number between min and max (inclusive)
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
  }

  const addCustomChoice = (choiceName) => {
    let legislationConfigGeos = props.usPrivacyRegulation.configByGeo;
    let newPurposes = { privacyChoice: choiceName, _id: `custom_${generateRandom10DigitNumber()}`, type: "CUSTOM"};
    newPurposes.configByGeo = Object.fromEntries(Object.entries(legislationConfigGeos).map(([key,value]) => {
      let newValue = { defaultLegalBasis: "NOT_APPLICABLE"};
      let newKey = key;
      if(legislationConfigGeos[key]?.legislation === "usnat" && !props.isNoFrameworkFlow) {
        newKey = key.split("_",1)[0];
      }
      return [newKey, newValue];
    }))
    let updatedRegulationCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
    let newCustomCategory = new SystemPurpose(newPurposes);
    updatedRegulationCategories.unshift(newCustomCategory.toJS());
    setRegulationCategories(updatedRegulationCategories)
    setOpenModalCustom(false)
    setCurrentRecord(null);
    checkForChangesDetectedErrorState();
  }

  const editCustomChoice = (choiceName, recordId) => {
    // let updatedRegulationCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
    // let choiceIndex = updatedRegulationCategories.findIndex(reg => reg.id === record.id);
    // updatedRegulationCategories[choiceIndex].privacyChoice = choiceName
    setRegulationCategories((regCats) => 
      regCats.map((cat) => {
        if(cat.id === recordId) {
          cat.privacyChoice = choiceName
        }
        return cat
    }) 
    )
    setOpenModalCustom(false)
    checkForChangesDetectedErrorState();
  }

  const menu = (
    <Menu>
      <Menu.Item disabled={filteredSystemPurpose.length === 0} title={filteredSystemPurpose.length === 0 ? "All IAB Choices have been added" : null} key="iab" onClick={() => handleModalVisibility('iab')}>{props.isNoFrameworkFlow ? "Standard" : "IAB"}</Menu.Item>
      <Menu.Item key="custom" onClick={() => handleModalVisibility('custom')}>Custom</Menu.Item>
    </Menu>
  );

  const handleDelete = (record) => {
    const updatedRegulationCategories = regulationCategories.filter(d => (d.id !== record.id));
    setRegulationCategories(updatedRegulationCategories)
  }

  const handleBulkDelete = async () => {
    const confirm = await modal.confirm({
      title: "Delete Choices",
      content: `Are you sure you want to delete ${selectedRowKeys?.length} Choices?`,
      width: 500,
    })
    if(confirm) {
      try {
        const updatedRegulationCategories = regulationCategories.filter(cat => !selectedRowKeys.includes(cat.id));
        setRegulationCategories(updatedRegulationCategories);
        message.success(selectedRowKeys?.length + " Privacy choices removed successfully");
        setSelectedRowKeys([])
      } catch(e) {
        console.log(e);
        message.error("Failed to remove Privacy choices");
      }
    }
    debugger;
  }

  const handleEditCustomCategory = (record) => {
    setCurrentRecord(record)
    setOpenModalCustom(true)
  }

 

  const columns = [
    {
      title: "Privacy Choice",
      fixed: "left",
      dataIndex: "privacyChoice",
      width: 200,
      sorter: (a, b) => a.privacyChoice.localeCompare(b.privacyChoice),
      render: (privacyChoice, record) => <Paragraph ellipsis={{tooltip:{title: privacyChoice}, rows: 2}}>{record.privacyChoice ?? record.name}</Paragraph>
    },
    {
      title: <>Privacy Choice ID {renderInfoTip("A unique ID associated to privacy choices that is specific to a vendor list.")}</>,
      fixed: "left",
      dataIndex: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
      showSorterTooltip: false,
      width: 200,
    },
    {
      title: <>System ID {renderInfoTip("A global ID for IAB privacy choices that stays the same across all vendor lists. Custom privacy choices don’t have one since they vary.")}</>,
      fixed: "left",
      dataIndex: "systemId",
      sorter: (a, b) => Number(a.systemId) - Number(b.systemId),
      showSorterTooltip: false,
      width: 200,
    },
    {
      title: "Type",
      fixed: "left",
      dataIndex: "type",
      width: 200,
      filters: [
        {
          icon: <i className="fas fa-circle" style={{ color: '#FCA015', width: 10, height: 10, marginRight: 10 }} />,
          text: props.isNoFrameworkFlow ? "Standard" : "IAB",
          value: "IAB",
        },
        {
          icon: <i className="fas fa-circle" style={{ color: '#C7C7C7', width: 10, height: 10, marginRight: 10 }} />,
          text: "Custom",
          value: "CUSTOM",
        },
      ],
      onFilter: (value, record) => {
        let vendorType = "";
       if (record.type === "SYSTEM_PURPOSE") {
          vendorType = "IAB"
        } else {
          vendorType = "CUSTOM"
        }
        return value == vendorType
      },
      render: (value) => {
        return value === "SYSTEM_PURPOSE" ? props.isNoFrameworkFlow ? <Tag color="blue">Standard</Tag> : <Tag color="orange">IAB</Tag> : <Tag color="magenta">Custom</Tag>
      }
    },
  ];

  if (!props?.readOnly) {
    columns.push({
      title: "Actions",
      fixed: "right",
      width: 150,
      align: "center",
      render: (record) => (
        <>
          {record.type !== "SYSTEM_PURPOSE" && (<Tooltip title="Edit"><Button icon={<EditOutlined />} type="link" onClick={() => handleEditCustomCategory(record)} /></Tooltip>)}
          <ConfirmationWrapper
            actionDescription="delete this Choice"
            deleteHandler={() => handleDelete(record)}
            deleteBtn={<Button type="link" icon={<Tooltip title="Delete"><DeleteOutlined /></Tooltip>} />}
          />
        </>
      ),
    },)
  }

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  let dataSource = searchValue.length ? _.cloneDeep(regulationCategories)?.filter(choice => choice.id?.toLowerCase().indexOf(searchValue) !== -1 || choice.privacyChoice.toLowerCase().indexOf(searchValue) !== -1) : _.cloneDeep(regulationCategories)
  dataSource = dataSource.map((record) => ({...record, key: record.id}));

  const rowSelection = {
    selectedRowKeys,
    onSelect : (record, selected, selectedRows, nativeEvent) => {
      if(selected) {
        setSelectedRowKeys((selectedKeys) => [... new Set(selectedKeys.concat(record.id))])
      } else {
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter(key => key !== record.id))
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        if(selected){
            setSelectedRowKeys((selectedKeys) => [...new Set(selectedKeys.concat(dataSource.map(v => v.id)))]);
        }else{
            setSelectedRowKeys((selectedKeys) => selectedKeys.filter((key) => !dataSource.map(v => v.id).includes(key)));
        }
    },
  }

  const disabledSellOrShare = props.usPrivacyRegulation.metaData.MspaCoveredTransaction && props.usPrivacyRegulation.metaData.MspaServiceProviderMode;

  return systemPurposesPending ? <Spin/> : (
    <>
      <Row gutter={[0, 16]}>
        {!props.readOnly ? (
          <Col span={24}>
            <Space direction="vertical" size={token.marginXXS}>
              <Title level={4}>Choice Selection</Title>
              <Text>Select the relevant data processing activities your company engages in</Text>
              {props.showError ? <Text type="danger">Select at least one Privacy Choice</Text> : null}
            </Space>
          </Col>
        ) : null}
        {regulationCategories.length > 0 && (
        <><Col span={8}>
          <Input.Search
            placeholder="search"
            type="search"
            onChange={({ target: { value } }) => {
                debouncedChangeHandler(value.trim().toLocaleLowerCase());
            }}
          />
        </Col>
        <Col span={8} offset={8}>
          {!props.readOnly ? (
            <Flex gap={token.margin} justify={"flex-end"}>
              {selectedRowKeys.length 
              ? (
              <>
                <Button type="link" icon={<MinusSquareOutlined/>} onClick={() => setSelectedRowKeys([])} disabled={props.readOnly}> Clear Selection</Button>
                {<Button type="primary" iconPosition="start" icon={<DeleteOutlined/>} onClick={handleBulkDelete} disabled={!selectedRowKeys.length || props.readOnly}> Bulk Delete ({selectedRowKeys.length})</Button>}
              </>
              ) : (
                <Dropdown.Button style={{width: "inherit"}} icon={<DownOutlined />} type="primary" overlay={menu} disabled={props.readOnly}> Add Choice</Dropdown.Button>
              )}
            </Flex>
          ) : null}
        </Col></>)}
        {regulationCategories.length === 0 && (
          <Flex flex="1" vertical align="center" justify="center" style={{ height: `500px`, background: 'white', border: props.showError ? "1px solid var(--ant-color-error)" : 0 }}>
            <Space direction="vertical" align="center" size={token.marginXXS}>
              <Typography.Title level={5} type="secondary">Start by adding your first privacy choice</Typography.Title>
              <Dropdown.Button style={{ width: 'auto' }} icon={<DownOutlined />} type="primary" overlay={menu}>Add Choice</Dropdown.Button>
            </Space>
          </Flex>
        )}
        {regulationCategories.length > 0 && (<Col span={24}>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowSelection={!props.readOnly ? rowSelection : null}
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => `Total ${total} items`,
              size:'small',
              defaultPageSize: props.readOnly ? 6 : 7,
              showSizeChanger: true,
              pageSizeOptions: ['7', '10', '20', '50'],
            }}
          />
        </Col>)}
      </Row>
      {cancelBtnTextAndOpenIabModal ?
        <AddIabChoicesModal
          cancelBtnTextAndOpenIabModal={cancelBtnTextAndOpenIabModal}
          handleCancel={handleCancel}
          addCategory={addIabChoice}
          systemPurposes={filteredSystemPurpose}
          isNoFrameworkFlow={props.isNoFrameworkFlow}
          disabledSellOrShare={disabledSellOrShare}
        />
        : null}
      {openModalCustom ?
        <AddCustomChoicesModal
          currentRecord={currentRecord}
          openCustomModal={openModalCustom}
          regulationCategories={regulationCategories}
          handleCancel={handleCancel}
          addCategory={addCustomChoice}
          editCustomChoice={editCustomChoice}
        />
        : null}
        {contextHolder}
    </>
  );
}

export default ChoiceSelection;