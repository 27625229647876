import { SCENARIO_STEPS, ScenarioRowRecord } from '../scenarios/versionTwo/components/steps';
import store from '../../../store.js'; 
import { List, Map } from 'immutable';
import {
  ShowMessageAlways,
  ShowMessageOnce,
  DelayNPageviews,
  TimeDelay,
  ShowMessageFreqCap,
  SessionMessageNLimit,
  ShowMessageNTimes
} from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { consentGateClass, kvStringClass } from './TemplateBuilder/Data';
import { systemKeyVals } from '../../../scenario_constants.js';

export function calculateInSeconds(value, msgDelayUnit) {
  let timeInSeconds = value;
  switch (msgDelayUnit) {
    case 'minutes':
      timeInSeconds = timeInSeconds * 60;
      break;
    case 'hours':
      timeInSeconds = timeInSeconds * 3600;
      break;
    case 'days':
      timeInSeconds = timeInSeconds * 86400;
      break;
    case 'weeks':
      timeInSeconds = timeInSeconds * 604800;
      break;
  }
  return timeInSeconds;
}

export function calculateTimeFromSeconds(value) {
  let timeValue = new Map({
    msgDelayUnit: 'days',
    timeSpan: 0,
  });
  let checkForMinutes = value % 60;
  let checkForHours = value % 3600;
  let checkForDays = value % 86400;
  let checkForWeeks = value % 604800;

  if (checkForWeeks === 0) {
    let msgTimeSpan = value / 604800;
    timeValue = timeValue
      .set('msgDelayUnit', 'weeks')
      .set('timeSpan', msgTimeSpan);
  } else if (checkForDays === 0) {
    let msgTimeSpan = value / 86400;
    timeValue = timeValue
      .set('msgDelayUnit', 'days')
      .set('timeSpan', msgTimeSpan);
  } else if (checkForHours === 0) {
    let msgTimeSpan = value / 3600;
    timeValue = timeValue
      .set('msgDelayUnit', 'hours')
      .set('timeSpan', msgTimeSpan);
  } else if (checkForMinutes === 0) {
    let msgTimeSpan = value / 60;
    timeValue = timeValue
      .set('msgDelayUnit', 'minutes')
      .set('timeSpan', msgTimeSpan);
  } else {
    let msgTimeSpan = value;
    timeValue = timeValue
      .set('msgDelayUnit', 'seconds')
      .set('timeSpan', msgTimeSpan);
  }
  return timeValue;
}

export function populateTemplates(stepList) {
  let steps = List([]);

  stepList.map((stepItem, rowIndex) => {
    let conditions = stepItem.get('conditions');
    let messages = stepItem.get('messages');
    let rowData = List([]);

    steps = steps.push(new ScenarioRowRecord());

    conditions.map((item, index) => {
      let className = item.get('className');
      let data = item.get('value');
      let stepBlockData = new SCENARIO_STEPS[className].Record();
      stepBlockData = stepBlockData.set('data', data);
      rowData = rowData.push(stepBlockData);
    });
    messages.map((item, index) => {
      let className = item.get('className');
      let data = item.get('value');
      let stepBlockData = new SCENARIO_STEPS[className].Record();
      stepBlockData = stepBlockData.set('data', data);
      rowData = rowData.push(stepBlockData);
    });
    steps = steps.setIn([rowIndex, 'rowData'], rowData);
  });
  return steps;
}
export const statusesGDPR = List([
  // Map({ label: 'Consented To All', value: 3 }),
  Map({ label: 'Not Consented To All', value: 4 }),
  Map({ label: 'Consented To Any', value: 1 }),
  Map({ label: 'Rejected To All', value: 2 }),
  Map({ label: 'Rejected To Any', value: 5 }),
  Map({ label: 'Not Rejected To Any', value: 6 }),
  Map({ label: 'Vendor List Additions', value: 7 }),
  Map({ label: 'Legal Basis Changes', value: 8 }),
  Map({ label: 'No Action', value: 0 }),
]);
export const statusesCCPA = List([
  Map({ value: 4, label: 'No Explicit Accept' }),
  Map({ value: 6, label: 'Not Rejected To Any' }),
  Map({ value: 0, label: 'No Action' }),
]);
export const statusesUSNAT = List([
  Map({ value: 0, label: 'No Action' }),
  Map({ value: 7, label: 'Vendor List Additions' }),
]);
export const statusesPreferences = List([
  Map({ value: 0, label: 'No Action' }),
  Map({ value: 7, label: 'Configuration Additions' }),
]);

export const statusesPreferencesUpdated = List([
  Map({ value: 'na', label: 'No Action Taken' }),
  Map({ value: 'a', label: 'Accepted' }),
  Map({ value: 'r', label: 'Rejected' }),
  Map({ value: 'od', label: 'Legal Doc Outdated' }),
]);

export function getTagData(className, data) {
  switch (className) {
    case 'urlMultipleRaw':
      const urlTagRaw = data
        .map((u, index) => {
          return getTagData(u.className, u.data);
        })
        .toString();
      return urlTagRaw;
    case 'urlMultiple':
      const urlTag = data
        .map((u, index) => {
          return getTagData(u.get('className'), u.get('data'));
        })
        .toJS()
        .toString();
      return urlTag;
    case 'kvMultipleRaw':
      const kvTagRaw = data
        .map((kv, index) => {
          return getTagData(kv.className, kv.data);
        })
        .toString();
      return kvTagRaw;
    case 'kvMultiple':
      const kvTag = data
        .map((kv, index) => {
          return getTagData(kv.get('className'), kv.get('data'));
        })
        .toJS()
        .toString();
      return kvTag;
    case 'timeDelay':
      const timeDelay = data
        .map((t) => {
          return getTagData(t.className, t.data)
        })
        .toString();
      return timeDelay;
    case 'com.sourcepoint.msgsvc.scenario.steps.GeoGate':
      return (
        SCENARIO_STEPS[className].label +
        ' ' +
        (data.continue ? 'In' : 'Not In') +
        ': ' +
        data.geo_list.join(',')
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.USStateGate':
      return (
        SCENARIO_STEPS[className].label +
        ' ' +
        (data.continue ? 'In' : 'Not In') +
        ': ' +
        data.state_list.join(',')
      );
    // case 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate': // DIA-4305 conflicting with the detailed case that includes usnat and preferences
    case 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2':
      const isOldConsentClassName = className === consentGateClass;
      const subLabel = (isOldConsentClassName && data.version !== 1) ?  (data.version === 2 ? ' (TCF v2)' : ' (U.S. Privacy)') : '';
      let statusV2 = statusesGDPR
        .find((s) => s?.get('value') === data.status)
        ?.get('label');
        statusV2 = !statusV2 ? (data.status == 3 && 'Consented To All') : statusV2;
      return SCENARIO_STEPS[className].label + subLabel + ': ' + statusV2;
    case 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA':
      const statusCcpa = statusesCCPA
        .find((s) => s.get('value') === data.status)
        .get('label');
      return SCENARIO_STEPS[className].label + ': ' + statusCcpa;
    case 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT':
      const statusUSNAT = statusesUSNAT
        .find((s) => s.get('value') === data.status)
        .get('label');
      return SCENARIO_STEPS[className].label + ': ' + statusUSNAT;
    case 'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences':
      const statusPreferences = statusesPreferences
        .find((s) => s.get('value') === data.status)
        .get('label');
      return SCENARIO_STEPS[className].label + ': ' + statusPreferences;
    case 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate':
      let status, act;
      if (data.version == 'ccpa') {
        act = '(U.S. Privacy)';
        status = statusesCCPA
          .find((s) => s.get('value') === data.status)
          .get('label');
      } else if (data.version === 2) {
        act = 'tcf v2';
        status = statusesGDPR?.find((s) => s.get('value') === data.status)?.get('label');
        status= !status ? (data.status == 3 && 'Consented To All') : status;
      } else if (data.version === 'usnat') {
        act = '(U.S. MultiState Privacy)';
        status = statusesUSNAT
          .find((s) => s.get('value') === data.status)
          .get('label');
      } else if (data.version === 'preferences') {
        act = '(Preferences)';
        status = statusesPreferences
          .find((s) => s.get('value') === data.status)
          .get('label');
      }
      return SCENARIO_STEPS[className].label + ' ' + act + ': ' + status;
    case 'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate':
      return (
        SCENARIO_STEPS[className].label +
        ': ' +
        (data.continue_on_satisfy ? 'Desktop' : 'Mobile')
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate':
    case 'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate':
    case 'com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate':
    case 'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate':
    case 'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate':
    case 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate':
      return (
        SCENARIO_STEPS[className].label + ': ' + (data.continue ? 'Yes' : 'No')
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate':
      return (
        SCENARIO_STEPS[className].label +
        ' ' +
        'Key' +
        ':' +
        data.k +
        ' ' +
        'Value' +
        ':' +
        data.v +
        ' ' +
        'Type (' +
        (data.allow_on_satisfy ? 'Include' : 'Exclude') +
        '): ' +
        data.type
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate':
      return (
        SCENARIO_STEPS[className].label +
        ' (' +
        (data.continue ? 'Yes' : 'No') +
        ')' +
        ': ' +
        data.ip_list.join(',')
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch':
      return (
        SCENARIO_STEPS[className].label +
        ' (' +
        (data.continue_on_satisfy ? 'Yes' : 'No') +
        ')' +
        ': ' +
        getStringsFromRegex(data.regex)
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate':
      return (
        SCENARIO_STEPS[className].label +
        ' (' +
        (data.allow_on_satisfy ? 'Yes' : 'No') +
        ')' +
        ': Key ' +
        data.k +
        ' Start ' +
        data.v_start +
        ' End ' +
        data.v_end
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.SambaCompGate':
    case 'com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate':
      return (
        SCENARIO_STEPS[className].label +
        ' (' +
        (data.continue ? 'Yes' : 'No') +
        ')'
      );
    case 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay':
      let timeRecord  = calculateTimeFromSeconds(data.delay_secs);
      let timeSpan = timeRecord.get('timeSpan');
      let timeUnit = timeRecord.get('msgDelayUnit')
      return `Delay Time : ${timeSpan} ${timeUnit}`;
    case 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews' :
      return `Delay Number of Pageviews : ${data.num_pv}`;
    default:
      if (className.startsWith(kvStringClass)) {
        if(data.k.includes('_sp_lt_')){
          const state = store.getState(); 
          const legalDocOptions = state.preferencesState.getIn(['campaignTypeList', 'value']);
          const key = data.k.substring("_sp_lt_".length);
          let docType = key?.split("_")[0]
          let status = statusesPreferencesUpdated?.find((s) => s.get('value') === key?.split("_")[1])?.get('label') || "";
          const label = legalDocOptions.find(opt => opt.type === docType)?.displayName;
          return (`Legal Status Transaction ${status}` + ': ' + label)  
        }else{
        let option = systemKeyVals[data.k].options.find(o => (o.value === data.v && !o.exclude === data.allow_on_satisfy ));
        return (SCENARIO_STEPS[className].label + ': ' + (option && option.label))
        }  
      }
      return (SCENARIO_STEPS[className].label)
  }
}

export const messageConditions = List([
  Map({
    key: 0,
    label: 'Show Message Always',
    className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
    dataRecord: ShowMessageAlways,
  }),
  Map({
    key: 1,
    label: 'Show Message Once',
    className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
    dataRecord: ShowMessageOnce,
  }),
  Map({
    key: 2,
    label: 'Mesage Frequency Cap',
    className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap',
    dataRecord: ShowMessageFreqCap,
  }),
  Map({
    key: 3,
    label: 'Session Message Number Limits',
    className: 'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit',
    dataRecord: SessionMessageNLimit,
  }),
  Map({
    key: 4,
    label: 'Show Message Num Times',
    className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes',
    dataRecord: ShowMessageNTimes,
  }),
  // "Show Message Num Times",
  // "Mesage Frequency Cap",
  // "Session Message Number Limits",
]);
export const messageDelay = List([
  Map({
    key: 0,
    label: 'Time',
    className: 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay',
    dataRecord: TimeDelay,
  }),
  Map({
    key: 1,
    label: 'PageViews',
    className: 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews',
    dataRecord: DelayNPageviews,
  }),
  Map({
    key: 3,
    label: 'None',
    className: '',
    dataRecord: null,
  }),
]);

export const messageRepeat = List([
  Map({
    key: 'No Repeat',
    label: 'No Repeat',
  }),
  Map({
    key: '0',
    label: '0',
  }),
  Map({
    key: '1',
    label: '1',
  }),
  Map({
    key: '2',
    label: '2',
  }),
  Map({
    key: '3',
    label: '3',
  }),
  Map({
    key: '4',
    label: '4',
  }),
  Map({
    key: '5',
    labels: '5',
  }),
]);

export function getMsgDeliveryValue(row) {
  let msgDelivery;
  if (
    row.some(
      (r) =>
        r.get('className') ==
        'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways'
    )
  ) {
    msgDelivery = 0;
  } else if (
    row.some(
      (r) =>
        r.get('className') ==
        'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce'
    )
  ) {
    msgDelivery = 1;
  } else if (
    row.some(
      (r) =>
        r.get('className') ==
        'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes'
    )
  ) {
    msgDelivery = 'Show Message Num Times';
  } else if (
    row.some(
      (r) =>
        r.get('className') ==
        'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap'
    )
  ) {
    msgDelivery = 'Mesage Frequency Cap';
  } else if (
    row.some(
      (r) =>
        r.get('className') ==
        'om.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit'
    )
  ) {
    msgDelivery = 'Session Message Number Limits';
  }
  return msgDelivery;
}
export function trimTag(string) {
  return string.length <= 30 ? string : string.substring(0, 30) + '...';
}

const escapeChars = [".", "/"]
function escapeRegexChars(string) {
  const regex = new RegExp("\\\\?([" + escapeChars.map(s => `\\${s}`).join('') + "])", "g")
  return string.replace(regex, "\\$1")
}
function unescapeRegexChars(string) {
  const regex = new RegExp("\\\\([" + escapeChars.map(s => `\\${s}`).join('') + "])", "g")
  return string.replace(regex, "$1")
}
export function regExp(condition, string) {
  switch (condition) {
    case 'beginWith':
      // remove existing start ^ if its there
      string = string.indexOf('^') === 0 ? string.substring(1) : string
      return '^(' + string + ').*';
    case 'endsWith':
      // remove existing end $ if its there
      string = string.indexOf('$') === string.length-1 ? string.substring(0, string.length - 1) : string
      return '.*(' + string + ')$';
    case 'contains':
      return '.*(' + string + ').*';
    case 'regex':
      return string;
  }
}
export function convertStringsToregex(strings, regex) {
  if (strings.size === 0) return "";
  return regex === 'regex' 
    ? strings.toJS().toString() 
    : regExp(
      regex, 
      strings
        .map(s => escapeRegexChars(s))
        .map(s => `(${s})`)
        .toJS()
        .join('|')
    )
}
export function getStringsFromRegex(strings) {
  const regexType = getRegexType(strings)

  return regexType === 'regex' 
    ? strings 
    : removeRegexFromString(strings)
      .split('|')
      .map(s => s.replace(/^\((.*)\)$/, "$1"))
      .map(s => unescapeRegexChars(s))
      .toString();
}

export function getSplitStringsFromRegex(strings) {
  return getStringsFromRegex(strings).split(',')
}

// these special chars should not be included in "helper" strings
const specialRegexChars = ['*', '$', '|', '(', ')', '[', ']', '{', '}'].map(c => `\\${c}`).join("")
// this builds a regex in the form of (string1)|(string2)|(string3)
const multiUrlRegex = `((\\([^${specialRegexChars}]*\\)[\\|])*(\\([^${specialRegexChars}]*\\)))`

const beginsWithRegEx = new RegExp(`^\\^\\(${multiUrlRegex}\\)\\.\\*$`)
const endsWithRegEx = new RegExp(`^\\.\\*\\(${multiUrlRegex}\\)\\$$`)
const containsRegEx = new RegExp(`^\\.\\*\\(${multiUrlRegex}\\)\\.\\*$`)

export function removeRegexFromString(string = '') {
  let str = string || '';
  if (beginsWithRegEx.test(str)) {
    str = str.replace(beginsWithRegEx, "$1");
  } else if (endsWithRegEx.test(str)) {
    str = str.replace(endsWithRegEx, "$1");
  } else if (containsRegEx.test(str)) {
    str = str.replace(containsRegEx, "$1");
  }

  return str;
}
export function getRegexType(strings) {
  const str = strings.split(',')[0] || '';
  if (endsWithRegEx.test(str)) {
    return 'endsWith';
  } else if (beginsWithRegEx.test(str)) {
    return 'beginWith';
  } else if (containsRegEx.test(str)) {
    return 'contains';
  } else {
    return 'regex'
  }
}
export function getMessageDelivery(record) {
  let className = record.get('className');
  let messageDelivery = 0;
  switch (className) {
    case 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways':
      messageDelivery = 0;
      break;
    case 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce':
      messageDelivery = 1;
      break;
    case 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap':
      messageDelivery = 2;
      break;
    case 'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit':
      messageDelivery = 3;
      break;
    case 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes' :
      messageDelivery = 4;
      break;
  }
  return messageDelivery;
}

export function getMessageDelay(record) {
  let className = record.get('className');
  let delayKey = Map({ key: '', value: 3 });
  switch (className) {
    case 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay':
      delayKey = Map({ key: 'delay_secs', value: 0 });
      break;
    case 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews':
      delayKey = Map({ key: 'num_pv', value: 1 });
      break;
  }
  return delayKey;
}

export const stateList = [
  {key: 'All', value: 'All US'},
  {key: "CA", value: "California"},
  {key: "AL", value: "Alabama"},
  {key: "AK", value: "Alaska"},
  {key: "AZ", value: "Arizona"},
  {key: "AR", value: "Arkansas"},
  {key: "CO", value: "Colorado"},
  {key: "CT", value: "Connecticut"},
  {key: "DE", value: "Delaware"},
  {key: "DC", value: "District Of Columbia"},
  {key: "FL", value: "Florida"},
  {key: "GA", value: "Georgia"},
  {key: "HI", value: "Hawaii"},
  {key: "ID", value: "Idaho"},
  {key: "IL", value: "Illinois"},
  {key: "IN", value: "Indiana"},
  {key: "IA", value: "Iowa"},
  {key: "KS", value: "Kansas"},
  {key: "KY", value: "Kentucky"},
  {key: "LA", value: "Louisiana"},
  {key: "ME", value: "Maine"},
  {key: "MD", value: "Maryland"},
  {key: "MA", value: "Massachusetts"},
  {key: "MI", value: "Michigan"},
  {key: "MN", value: "Minnesota"},
  {key: "MS", value: "Mississippi"},
  {key: "MO", value: "Missouri"},
  {key: "MT", value: "Montana"}, 
  {key: "NE", value: "Nebraska"},
  {key: "NV", value: "Nevada"},
  {key: "NH", value: "New Hampshire"},
  {key: "NJ", value: "New Jersey"},
  {key: "NM", value: "New Mexico"},
  {key: "NY", value: "New York"},
  {key: "NC", value: "North Carolina"},
  {key: "ND", value: "North Dakota"},
  {key: "OH", value: "Ohio"},
  {key: "OK", value: "Oklahoma"},
  {key: "OR", value: "Oregon"},
  {key: "PA", value: "Pennsylvania"},
  {key: "RI", value: "Rhode Island"},
  {key: "SC", value: "South Carolina"},
  {key: "SD", value: "South Dakota"},
  {key: "TN", value: "Tennessee"},
  {key: "TX", value: "Texas"},
  {key: "UT", value: "Utah"},
  {key: "VT", value: "Vermont"},
  {key: "VA", value: "Virginia"},
  {key: "WA", value: "Washington"},
  {key: "WV", value: "West Virginia"},
  {key: "WI", value: "Wisconsin"},
  {key: "WY", value: "Wyoming"}
];