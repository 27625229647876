import React, { useState, useEffect,useRef } from 'react';
import { Button, Modal, Upload, message, Space, Divider, Typography, Row, Col, Tabs } from 'antd';
import { findObjectByKeyRef } from "./helper";
import { CheckCircleFilled, DownloadOutlined, InboxOutlined, LeftOutlined } from "@ant-design/icons";
import { SelectWithSelectAll } from '../../../../../../styleguide';
import { COUNTRY_LANGUAGES } from '../../../../../../constants';
import { convertToCSV } from '../../../../../utils.js';

const { Dragger } = Upload;

const tabsFieldTranslationkey = { "1": "names", "2": "descriptions" }

const BulkUploadModal = (props) => {
    const { closeModal, selectedLang, purposeOptions,descriptionOptions,regulationCategories,uploadTranslations,activeTab } = props;
    const [choiceValue, setChoiceValue] = useState(1);
    const [languages, setLanguages] = useState(selectedLang);
    const [purposes, setPurposes] = useState(purposeOptions.map(p => p.value));
 
    const parseCsvRow = (row) => {
        const regex = /("(?:(?:"")*[^"]*)*"|[^,]+)(?:,|$)/g;
        const values = [];
        let match;

        while ((match = regex.exec(row))) {
            values.push(match[1].replace(/""/g, '"'));
        }

        return values;
    };

    const choiceValueRef = useRef(choiceValue);

    useEffect(() => {
        // Update the ref when choiceValue changes
        choiceValueRef.current = choiceValue;
    }, [choiceValue]);


    const beforeUpload = async (file) => {
        const reader = new FileReader();
        const currentChoiceValue = choiceValueRef.current;
        const sampleData = getSampleData(currentChoiceValue);
    
        if (file) {
          const allowedExtensions = ["csv", "xlsx", "xls"];
          const fileExtension = file.name.split(".").pop().toLowerCase();
    
          if (allowedExtensions.includes(fileExtension)) {
            reader.readAsText(file, "UTF-8");
            reader.onload = (e) => {
              const fileContent = e.target.result;
              const parsedData = [];
              
              // Split rows, filtering out completely empty rows
              const rows = fileContent.split(/\r\n|\r|\n/).filter(row => row.trim() !== '');
              
              // Handle headers separately
              const headers = rows[0].split(',').map(header => header.trim().replace(/^"(.*)"$/, '$1'));
              
              // Process data rows
              for (let i = 1; i < rows.length; i++) {
                const rowData = parseCsvRow(rows[i]);
                
                // Only create an object if we have the same number of values as headers
                if (rowData.length === headers.length) {
                  const rowObj = {};
                  
                  for (let j = 0; j < headers.length; j++) {
                    const key = headers[j];
                    const value = rowData[j];
                    
                    // Only add non-empty values
                    if (value !== undefined) {
                      rowObj[key] = value;
                    }
                  }
                  
                  // Only add the row if it has some content
                  if (Object.keys(rowObj).length > 0) {
                    parsedData.push(rowObj);
                  }
                }
              }
              
              uploadTranslations(parsedData, sampleData, currentChoiceValue);
            };
            
            return false;
          }
        }
    };

    const onChange = (key) => {
        const selectedTab = Number(key);
        const options = selectedTab === 1 ? purposeOptions : descriptionOptions;
        const values = selectedTab === 1 ? purposeOptions.map((p) => p.value) : descriptionOptions.map((p) => p.value);
        setChoiceValue(selectedTab);
        setOptions(options)
        setPurposes(values);
        updateSampleData(values, selectedTab)
    };


    const uploadParams = {
        name: 'file',
       // accept: '.csv',
        maxCount: 1,
        openFileDialogOnClick: true,
        beforeUpload: beforeUpload,
        onChange: (props) => {
            if(props.file.status == "error") {
              props.file.response = "Invalid file format."
              message.error("Invalid file format.")
            }else if(!props.file.status){
              props.file.response = "File uploaded successfully"
              message.success("File uploaded successfully.")
            }
          }
    };

    const [sampleData, setSampleData] = useState([]);
    const [uploadProps, setUploadProps] = useState(uploadParams)
    const [showSuccessMessage, setShowSuccessMsg] = useState(false)
    const [options, setOptions] = useState(purposeOptions)

    let languageOptions = COUNTRY_LANGUAGES.toJS().filter((c => selectedLang?.includes(c.code))).map((l, i) => ({
        label: l.language,
        value: l.code,
    }));
    
    const handleSelectedLanguage = (value) => {
        setLanguages(value)
    }
    const handleSelectedPurpose = (value) => {
        setPurposes(value)
    }

    useEffect(() => {
        updateSampleData(purposes,choiceValue)
    }, [languages, purposes,choiceValue])

    const updateSampleData = (options, choiceValue) => {
        let sampleData = [];
        const selLanguages = COUNTRY_LANGUAGES.filter(lang => languages.includes(lang.code)).map(l => l.language)
        const fieldTranslationkey = tabsFieldTranslationkey[choiceValue.toString()]
        options.map(opt => {
            let purpose = findObjectByKeyRef(regulationCategories, 'categories', 'purposeRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', 'systemStackRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', 'id', opt);
            if (purpose) {
                const newData = {
                    "Privacy Choice": purpose?.privacyChoice || purpose?.name,
                };
                selLanguages.forEach(lang => {
                    const langCode = COUNTRY_LANGUAGES.find(l => l.language === lang)?.code.toLowerCase();
                    const negTrans = purpose?.negativeTranslations[fieldTranslationkey][langCode] || "";
                    const positive = purpose?.translations[fieldTranslationkey][langCode] || "";
                    let translation = purpose.isNegative ? negTrans : positive;
                    newData[lang] = translation || ""; // Initialize other language keys with empty string
                });
                sampleData.push(newData)
            }
        })
        const csvContent = (sampleData.length > 0) ? convertToCSV(sampleData) : "";
        setSampleData(csvContent);
    }

    const getSampleData = (activeTab) => {
        let sampleData = [];
        const selLanguages = COUNTRY_LANGUAGES.filter(lang => languages.includes(lang.code)).map(l => l.language)
        const fieldTranslationkey = tabsFieldTranslationkey[activeTab.toString()]
        const options = activeTab === 1 ? purposeOptions.map(p => p.value) : descriptionOptions.map(p => p.value);
        options.map(opt => {
            let purpose = findObjectByKeyRef(regulationCategories, 'categories', 'purposeRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', 'systemStackRef', opt) || findObjectByKeyRef(regulationCategories, 'categories', "id",opt);
            if (purpose) {
                const newData = {
                    "Privacy Choice": purpose?.privacyChoice || purpose?.name,
                };
                selLanguages.forEach(lang => {
                    const langCode = COUNTRY_LANGUAGES.find(l => l.language === lang)?.code.toLowerCase();
                    const negTrans = purpose?.negativeTranslations[fieldTranslationkey][langCode] || "";
                    const positive = purpose?.translations[fieldTranslationkey][langCode] || "";
                    let translation = purpose.isNegative ? negTrans : positive;
                    newData[lang] = translation || ""; // Initialize other language keys with empty string
                });
                sampleData.push(newData)
            }
        })
        
        return sampleData
    }

    const handleDownload = (e) => {
        //const utf8Bytes = new TextEncoder().encode(sampleData);
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), sampleData], { type: 'text/csv;charset=utf-8' });
        //const blob = new Blob([utf8Bytes], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        //const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = new Date() + 'Bulk_Upload.csv';
        a.click();
        URL.revokeObjectURL(url);
        setShowSuccessMsg(true)
        e.stopPropagation()
    };


    return (
        <Modal
          wrapClassName={`bulk-upload`}
          open={true}
          visible={true}
          footer={[
            <Button onClick={closeModal}>
              Cancel
            </Button>,
          ]}
          onCancel={closeModal}
          title={
            <Space>
              <Button
                icon={<LeftOutlined />}
                type="text"
                size="middle"
                onClick={closeModal}
              />
              <div>
                <Typography.Text level={4}>
                  {"Bulk Upload Translations"}
                </Typography.Text>
              </div>
            </Space>}
          width={ 720}
          style={{
            top: "5%",
          }}
        >
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Tabs onChange={onChange}>
                <Tabs.TabPane tab="Choice Text" key={1}></Tabs.TabPane>
                <Tabs.TabPane tab="Description" key={2}></Tabs.TabPane>
              </Tabs>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size={4}>
                <Typography.Text>Language(s)</Typography.Text>
                <SelectWithSelectAll
                  options={languageOptions}
                  onChange={(value) => handleSelectedLanguage(value)}
                  value={languages}
                  width={"334px"}
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size={4}>
                <Typography.Text>Categories</Typography.Text>
                <SelectWithSelectAll
                  options={options}
                  onChange={(value) => handleSelectedPurpose(value)}
                  value={purposes}
                  width={"334px"}
                />
              </Space>
            </Col>
            <Col span={12}>
              {showSuccessMessage ? (
                <Typography.Text type="success">
                  {" "}
                  <CheckCircleFilled /> Successfully Downloaded
                </Typography.Text>
              ) : null}
            </Col>
            <Col span={8} offset={4}>
              <Button onClick={handleDownload}>
                <DownloadOutlined />
                Download CSV Template
              </Button>
            </Col>
            <Col span={24} flex="auto">
              <Divider style={{margin:"16px 0"}}/>
              <Space direction="vertical" size={4} style={{width: "100%"}}>
                <Typography.Text>Upload translations CSV</Typography.Text>
                <Typography.Text type='secondary'>This action will overwrite data only for the selected privacy choices.</Typography.Text>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </Dragger>
              </Space>
            </Col>
          </Row>
        </Modal>
      );
}

export default BulkUploadModal;
