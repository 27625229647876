import React from "react";
import { Table } from "antd";
import SVG from "react-inlinesvg";
import redirectIcon from "../../../../../../assets/icons/link.svg";
import { region_title_index_map } from "../../../../../../constants";

const legalBasisNames = {
  CONSENT: "Opt In",
  "OPT-OUT": "Opt Out",
};

const PrivacyChoicesTable = ({ usPrivacyRegulation }) => {
  const columns = [
    {
      title: "Type",
      dataIndex: "defaultLegalBasis",
      key: "defaultLegalBasis",
      width: "20%",
      render: (text) => legalBasisNames[text],
    },
    {
      title: "Privacy Choice",
      dataIndex: "privacyChoice",
      key: "privacyChoice",
      width: "30%",
      render: (text,record) => text,
    },
    {
      title: "Privacy Policy Section Link",
      dataIndex: "privacyPolicySectionLink",
      key: "privacyPolicySectionLink",
      width: "20%",
      render: (link) => link ? (
        <a href={link} target="_blank">
         Link <SVG className="redirect" src={redirectIcon}/>
        </a>
      ) : "-",
    },
    {
      title: "Additional Information",
      dataIndex: "categories",
      key: "categories",
      width: "30%",
      render: (categories, PurposeRecord) => {
        if (PurposeRecord.type == "SYSTEM_STACK") {
          return (
            <div>
              Opt In to process the consumer's sensitive data consisting of{" "}
              <span className="more-categories">{categories.length}</span>
            </div>
          );
        }
        if (PurposeRecord.type == "SYSTEM_PURPOSE") {
          return (
            <div>
              <div>
                {PurposeRecord.respectGPC ? "" : "Does not "} Respects{" "}
                <a
                  href="https://docs.sourcepoint.com/hc/en-us/articles/8357018608019-Global-Privacy-Control-GPC-"
                  target="_blank"
                >
                  Global Privacy Control
                <SVG className="redirect" src={redirectIcon} />
                </a>{" "}
              </div>
              {PurposeRecord.respectGPC ? (
                <div style={{display:'flex', alignItems: 'baseline'}}>
                  Territories:{" "} <span className="more-categories">{region_title_index_map[PurposeRecord.respectGPC?.[0]]}</span><span className="more-categories">+{PurposeRecord.respectGPC?.length - 1}</span>
                </div>
              ) : null}
            </div>
          );
        }
      },
    },
  ];

  return (
    <Table
      // className="opt-in-out-hooks th-white"
      pagination={{
        position: ['bottomCenter'],
        showTotal: (total) => `Total ${total} items`,
        size:'small',
        defaultPageSize: 6,
        showSizeChanger: true,
        pageSizeOptions: ['7', '10', '20', '50'],
      }}
      columns={columns}
      dataSource={usPrivacyRegulation.categories}
      bordered={false}
    />
  );
};

export default PrivacyChoicesTable;
