import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import { RenderLimitedChips, SelectDropdown, message } from '../../../../../../styleguide';
import {AddEditDelete} from '../../../../../../styleguide';
import classNames from 'classnames';
import { trimString } from '../../../../../utils';
import { getVendorIcon, renderInfoTip, wrapInInfotip } from '../../../helper';
import { CopyOutlined } from '@ant-design/icons';
import SVG from "react-inlinesvg";

const VendorsTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [vendorTypeFilter, setVendorTypeFilter] = useState([]);
  const [choiceTypeFilter, setChoiceTypeFilter] = useState([]);

  const handleChoiceEdit = (vendorId, categories) => {
    props.vendorsHashMap[vendorId] = categories;
    if(props.updateInstantly){
      props.updateInstantly(vendorId, categories)
      setSelectedRowKeys([])
      props.setSelectedVendors([])
    }
  }

  useEffect(()=>{
    if(props.selectedVendors?.length == 0){
      setSelectedRowKeys([])
    }
  },[props.selectedVendors])

  const applyVendorTypeFilter = (records, vendorTypeFilter) => {
    if (vendorTypeFilter.length > 0) {
      return records.filter(record => {
        let vendorType = "";
        if (record.ids?.mspaId) vendorType = "MSPA";
        else vendorType = "CUSTOM";
        return vendorTypeFilter[0] === vendorType;
      });
    }
    return records;
  };
  
  const applyChoiceTypeFilter = (records, choiceTypeFilter) => {
    if(choiceTypeFilter.length > 0) {
      return records.filter(record =>  props.vendorsHashMap[record.vendorId]?.includes(choiceTypeFilter[0]))
    }
    return records;
  }
  const getVendorIdsFromRecords = (records) => {
    return records.map(v => v.vendorId);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected) => {
      const vendorId = record.vendorId;
      setSelectedRowKeys((selectedKeys) => selected ? [...new Set([...selectedKeys, vendorId])] : selectedKeys.filter(key => key !== vendorId));
      props.setSelectedVendors((existingState) => selected ? [...new Set([...existingState, vendorId])] : existingState.filter(key => key !== vendorId));
    },
    onSelectAll: (selected) => {
      let filteredRecords = applyVendorTypeFilter(props.dataSource, vendorTypeFilter);
      filteredRecords = applyChoiceTypeFilter(filteredRecords, choiceTypeFilter);
      const vendorIds = getVendorIdsFromRecords(filteredRecords);
  
      setSelectedRowKeys((selectedKeys) => selected ? [...new Set([...selectedKeys, ...vendorIds])] : selectedKeys.filter(key => !vendorIds.includes(key)));
      props.setSelectedVendors((existingState) => selected ? [...new Set([...existingState, ...vendorIds])] : existingState.filter(key => !vendorIds.includes(key)));
    },
    checkStrictly: false
  };

  const handleCopyClick = async (elementId) => {
    try {
      const textToCopy = document.getElementById(elementId).innerText;
      await navigator.clipboard.writeText(textToCopy);
      message.info('Vendor Id copied', 1)
    } catch (err) {
      message.info('Failed to copy Vendor Id', 1)
    }
  };

  const renderBulkEditTitle = () => {
    return (
      <div className='bulk-edit-choices'>
        <div>Privacy choices</div>
        <div>Select updated Privacy Choice</div>
        <SelectDropdown
          value={props.selectedCategories}
          options={props.categoryOptions}
          onChange={(categories) => props.setSelectedCategories(categories)}
          selectAll
          multiple
          entityName="Privacy Choices"
          error={props.triggerBulkEditError}
          directUpdate
        />
      </div>
    )
  }


  const columns = [
    {
      title: 'Vendor',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width:'30%',
      className: 'vendor-name',
      // render: renderVendorName,
      //ellipsis: true,
      filters: props.isBulkEdit ? null : [
        {
          text: <><SVG src="/svg_icons/Ellipse.svg" className="ellipse-icon cyan-7"/>MSPA Signatory / Certified</>,
          value: 'MSPA',
        },
        {
          text: <><SVG src="/svg_icons/Ellipse.svg" className="ellipse-icon magenta-6"/> Custom</>,
          value: 'CUSTOM',
        },
      ],
      onFilter: props.isBulkEdit ? null :  (value, record) => {
        let vendorType = "";
        if(record.ids?.mspaId){
          vendorType = "MSPA"
        } else {
          vendorType = "CUSTOM"
        }
        return value == vendorType
      },
      filterMultiple: false,
      render: (name, vendorRecord) => {
        const icon = getVendorIcon(vendorRecord);
        let suffixInfotTip = null;
        if(props.overlappingVendorIds?.includes(vendorRecord.vendorId)) {
          suffixInfotTip = renderInfoTip("Vendor already added", "warning")
        }
        const elementId = `vendor_id_${vendorRecord.vendorId}`
        const content = <div className='vendor-name-infotip'><p>{icon} {vendorRecord.ids?.mspaId ? "MSPA Signatory / Certified" : "Custom"}</p><span>ID: <span id={elementId} className='vendor-id'>{vendorRecord.vendorId}</span></span><CopyOutlined onClick={() => handleCopyClick(elementId)}/></div>
        return (
          wrapInInfotip(<span>
            {icon} {trimString(name, 20)}{suffixInfotTip}
          </span> , content)
        )
      }
    },
    {
      title: props.isBulkEdit ? renderBulkEditTitle() : "Privacy Choice",
      dataIndex: ['categories'],
      width:'65%',
      className: classNames('privacy-choice-column', props.isBulkEdit ? 'bulk-edit' : ''),
      render: (value, record) => (
        props.readOnly ? 
        <RenderLimitedChips chips={(props.vendorsHashMap[record.vendorId] ?? [])?.map(v => <Tag title={props.categoryOptions.find(option => option.value == v)?.label} style={{maxWidth: 200,  overflow: 'clip', textOverflow: "ellipsis" }}>{props.categoryOptions.find(option => option.value == v)?.label}</Tag>) ?? []} cutOff={2}/> 
        : props.isAddSysVendorsMode ? 
          <SelectDropdown
            value={props.vendorsHashMap[record.vendorId] ?? []}
            options={props.categoryOptions}
            onChange={(categories) => handleChoiceEdit(record.vendorId, categories)}
            selectAll
            multiple
            entityName="Privacy Choices"
            directUpdate
          />
        : (
          <AddEditDelete 
            validate={()=>{}}
            value={props.vendorsHashMap[record.vendorId] ?? []}
            handleSave={(categories) => handleChoiceEdit(record.vendorId, categories)}
            entityName="Privacy Choices"
            deleteEntityName="Vendor"
            suffixNode={null}
            dropdownInput
            dropdownOptions={props.categoryOptions}
            spaceBetween
          />
        )
      ),
      filters: props.isBulkEdit ? null :  props.categoryOptions.map(c => ({text: <span title={c.label}>{trimString(c.label, 50)}</span> , value: c.value, searchValue: c.label})),
      onFilter: props.isBulkEdit ? null :  (value, record) => props.vendorsHashMap[record.vendorId]?.includes(value),
      filterSearch: (input, record) => {
        return record.searchValue.toLowerCase().indexOf(input.trim().toLowerCase()) !== -1;
      }
    },
  ]

  let emptyTableText = props.isAddSysVendorsMode ? (
    props.isImport ? (
      <>
        <div style={{ fontSize: "14px" }}>
          Imported Vendors will appear here
        </div>
        To import vendors, Select framework and vendor list from dropdowns and
        click on <b>Import Vendors</b>.
      </>
    ) : props.vendorScanResults ? (
      <>No vendors found</>
    ) : (
      <>All System Vendors are added to the Vendor List</>
    )
  ) : (
    <>
      <div>Empty Vendor List</div>
      {props.readOnly ? null : (
        <div>
          To add vendors, click on <b>+Add Vendors</b> button
        </div>
      )}
    </>
  );
  emptyTableText = props.emptyTableTextCheck ? "No Data" : emptyTableText;

  const onVendorTableChange = (pagination, filters, sorter) => {
    setVendorTypeFilter(filters.name ?? []);
    setChoiceTypeFilter(filters.categories ?? []);
    props.setVendorTableFilters(filters);
  }

  const isNotMainTable = props.isBulkEdit || props.isAddSysVendorsMode || props.isImport;
  return (
    <Table
      className={classNames("usnat-vendors-table", {"min-height" : props.dataSource.length == 0, "th-white" : props.readOnly && !props.vendorScanResults }, props.classList)}
      pagination={{
        position: ['bottomCenter'],
        showTotal: (total) => `Total ${total} items`,
        size:'small',
        defaultPageSize: props.readOnly ? 6 : 7,
        showSizeChanger: true,
        pageSizeOptions: ['7', '10', '20', '50'],
      }}
      locale={{ emptyText: emptyTableText }}
      loading={props.isLoading}
      rowKey={(record) => `${record.vendorId}`}
      scroll={{  y: isNotMainTable ? 300 : 600}}
      dataSource={props.dataSource}
      columns={columns}
      rowSelection={!props.readOnly && rowSelection}
      onChange={onVendorTableChange}
    />
  )

}

export default VendorsTable;