import { Alert, Flex, Modal, Select, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { LEGALBASIS_OPTIONS, renderInfoTip, StateCodeRender } from "../../../helper";

const { Text, Paragraph } = Typography;

const BulkRegionMappingModal = (props) => {
  const [categories, setCategories] = useState(_.cloneDeep(props.categories));
  const [privacySignalConfigByGeo, setPrivacySignalConfigByGeo] = useState(_.cloneDeep(props.privacySignalConfigByGeo));
  const [dataSource, setDataSource] = useState(_.cloneDeep(props.selectedRegions));

  function handlePrivacySignalUpdate(bulkPrivacySignal) {
    setPrivacySignalConfigByGeo(prevConfig => {
      const newConfig = {...prevConfig}; // Create a new object with spread
      dataSource.forEach(({region}) => {
        newConfig[region] = {legislation: bulkPrivacySignal}
      })
      return newConfig; // Return the new object
    });
    setCategories((categories) => categories.map((cat) => {
      dataSource.forEach(({region}) => {
        if(bulkPrivacySignal === "usnat") {
          delete cat.configByGeo[region];
        } else {
          cat.configByGeo[region] = cat.configByGeo[region.split("_",1)[0]] ?? undefined
        }
      })        
      return cat;
    }))
  }

  function handleCateogyLegalBasisChagne(categoryId, bulkLegalBasis) {
    setCategories((categories) => categories.map((cat) => {
      if(cat.id === categoryId) {
        dataSource.forEach(({region}) => {
          if(privacySignalConfigByGeo[region]?.legislation === "usnat" && !props.isNoFrameworkFlow) {
            // cat.configByGeo[region.split("_",1)[0]] = {defaultLegalBasis: bulkLegalBasis};
            props.usnatRegionsToUpdate.forEach((region) => {
              cat.configByGeo[region] = {defaultLegalBasis: bulkLegalBasis};
            })
          } else {
            cat.configByGeo[region] = {defaultLegalBasis: bulkLegalBasis}
          }
        });
      }
      return cat;
    }))
  }

  function handleSaveChange() {
    props.setCategories(categories);
    props.setPrivacySignalConfigByGeo(privacySignalConfigByGeo);
    props.closeModal();
  }


  useEffect(() => {
    setDataSource((prevDataSource) => {
      return prevDataSource.map((data) => {
        const newData = { ...data };
        categories.forEach((cat) => {
          if (cat.configByGeo) {
            const defLegBasisRecord = privacySignalConfigByGeo[data.region]?.legislation === "usnat" && !props.isNoFrameworkFlow ? cat.configByGeo[data.region?.split("_",1)[0]] : cat.configByGeo[data.region];
            if (defLegBasisRecord) {
              newData.legalBasis = newData.legalBasis || {};
              newData.legalBasis[cat.id] = defLegBasisRecord.defaultLegalBasis;
            }
          }
        });
        newData.privacySignal = privacySignalConfigByGeo[data.region]?.legislation;
        return newData;
      });
    });
  }, [categories, privacySignalConfigByGeo]);

  let privacySignalOptions = [{label: "National", value: "usnat"}];
  if(props.selectedRegions.every(({region}) => props.stateStringRegions[region])) {
    privacySignalOptions.push({label: "State", value: "state"})
  }

  const columns = [
    {
      title: "Region",
      fixed: "left",
      dataIndex: "region",
      width: 200,
      render: (stateCode, record) => {
        return (
          <Flex gap={12} align="center">
            <Text>{record.title}</Text>
            {props.isNoFrameworkFlow ? null : <StateCodeRender stateCode={record.region}/>}
          </Flex>
        );
      },
    }
  ]
  if(!props.isNoFrameworkFlow) {
    columns.push(
      {
        title: ({ sortOrder, sortColumn, filters }) => {
          return (
          <Flex vertical gap={12}>
            <Typography.Text>Privacy Signal {renderInfoTip(<>National Signal: To use the us_nat signal in compliance with IAB guidelines ensure you have signed the MSPA.<br/>State Signal: Before choosing to send a state signal, verify that the vendors you work with, support it.</>)}</Typography.Text>
            <Select
              onChange={(value) => handlePrivacySignalUpdate(value)}
              options={privacySignalOptions}
              style={{minWidth: 150}}
            />
          </Flex>
        )},
        fixed: "left",
        dataIndex: "privacySignal",
        width: 200,
        render: (value, record) => {
          return (
            <Select
              value={value}
              disabled={true}
              options={[{label: "National", value: "usnat"}, {label: "State", value: "state"}]}
              style={{minWidth: 150}}
            />
          )
        }
      }
    )
  }

  categories.forEach((cat) => {
    const isIabCat = cat.type === "SYSTEM_PURPOSE";
    let options = LEGALBASIS_OPTIONS;
    if(!isIabCat) {
      options = [...options, {label: "Disclosure Only", value: "DISCLOSURE_ONLY"}]
    } 
    columns.push({
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
        <Flex vertical gap={12}>
          <Space direction="vertical">
            <Paragraph style={{maxWidth: 150}} ellipsis={{tooltip:{title: cat.privacyChoice ?? cat.name}, rows: 2}}>{cat.privacyChoice ?? cat.name}</Paragraph>
            <Tag color={isIabCat ? "orange" : "magenta"}> {isIabCat ? props.isNoFrameworkFlow ? "Standard" : "IAB" : "Custom"}</Tag>
          </Space>
          <Select
            onChange={(value) => handleCateogyLegalBasisChagne(cat.id, value)}
            options={options}
            style={{minWidth: 150}}
          />
        </Flex>
      )},
      width: 200,
      dataIndex: ["legalBasis", cat.id],
      render: (value, record) => {
        return (
          <Select
            value={value ?? "NOT_APPLICABLE"}  
            defaultValue={!Boolean(value) ? "NOT_APPLICABLE" : null}
            options={options}
            placeholder="Select"
            disabled={true}
            style={{minWidth: 150}}
          />
        )
      }
    })
  })

  return (
    <Modal
      open={props.openModal}
      title="Bulk Edit"
      okText="Save"
      onOk={handleSaveChange}
      cancelButtonProps={{
        type: "link"
      }}
      onCancel={props.closeModal}
      width={"80vw"}
    >
      <Flex vertical gap={16}>
        <Alert type="info" showIcon closable message="Note: Bulk operations will not affect the states where the privacy choice configuration is not editable."/>
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 1000}}
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => <div>Total Regions :{' '}{total}</div>,
            size: 'small',
            defaultPageSize: 7,
            showSizeChanger: true,
            pageSizeOptions: ['7', '10', '15', '20'],
          }}
        />
      </Flex>
    </Modal>
  )
}

export default BulkRegionMappingModal;