import React, { useEffect, useState } from "react";
import { Divider, Tag, Tooltip, Button, Typography, Space, Radio } from "antd";
import { Popconfirm } from "../../../styleguide";
import { CloseOutlined, EyeFilled } from "@ant-design/icons";
import { GEO_LIST_TREE_STRUCTURE, region_title_index_map } from "../../../constants";
import _ from "lodash";
import { StateCodeRender } from "../../consent/us_privacy_regulation/helper";

const region_key_index_map = GEO_LIST_TREE_STRUCTURE.reduce((map, item) => {
  item.children.forEach((child) => {
    if (!map[child.code]) {
      map[child.code] = [child.key];
    } else if (Array.isArray(map[child.code])) {
      map[child.code].push(child.key);
    } else {
      map[child.code] = [map[child.code], child.key];
    }
  });
  return map;
}, {});

const SelectedRegionsView = (props) => {
  const [selectedTerritories, setSelectedTerritories] = useState([
    {
      key: "US",
      included: [],
      excluded: [],
    },
    {
      key: "EEA",
      title: "EEA + UK",
      included: [],
      excluded: [],
    },
    {
      key: "CA",
      included: [],
      excluded: [],
    },
    {
      key: "OTHER",
      included: [],
      excluded: [],
    },
  ]);
  const [regionViewStateMap, setRegionViewStateMap] = useState({
    US: { showIncludes: true, count: 5 },
    EEA: { showIncludes: true, count: 5 },
    CA: { showIncludes: true, count: 5 },
    OTHER: { showIncludes: true, count: 5 },
  });

  useEffect(() => {
    let selectedTerritories = [
      {
        key: "US",
        included: [],
        excluded: [],
      },
      {
        key: "EEA",
        title: "EEA + UK",
        included: [],
        excluded: [],
      },
      {
        key: "CA",
        included: [],
        excluded: [],
      },
      {
        key: "OTHER",
        included: [],
        excluded: [],
      },
    ];
    props.selectedCodes.forEach((code) => {
      const keys = region_key_index_map[code];
      const regions = keys?.map((key) => key.split(";")[0]);
      regions?.forEach((region) => {
        let includedCodes = selectedTerritories.find(
          (t) => t.key === region
        ).included;
        includedCodes.push(code);
        selectedTerritories.find((t) => t.key === region).excluded =
          GEO_LIST_TREE_STRUCTURE.find((o) => o.code === region)
            .children.filter((c) => !includedCodes.includes(c.code))
            .map((c) => c.code);
      });
    });
    setSelectedTerritories(selectedTerritories);
    if(props.setSelectedTerritoriesData) props.setSelectedTerritoriesData(selectedTerritories);
  }, [props.selectedCodes]);

  const flipTerritoryView = (territoryKey) => {
    const dupli = _.cloneDeep(regionViewStateMap);
    dupli[territoryKey].showIncludes = !dupli[territoryKey].showIncludes;
    dupli[territoryKey].count = 5;
    setRegionViewStateMap(dupli);
  };

  const handleViewMore = (territoryKey, length) => {
    const dupli = _.cloneDeep(regionViewStateMap);
    dupli[territoryKey].count = 1000; // any number that is greater than the possible number of regions in a region
    setRegionViewStateMap(dupli);
  };

  const handleViewLess = (territoryKey) => {
    const dupli = _.cloneDeep(regionViewStateMap);
    dupli[territoryKey].count = 5;
    setRegionViewStateMap(dupli);
  };

  const removeTerritory = (removeCode) => {
    props.setSelectedCodes(props.selectedCodes.filter((code) => removeCode !== code));
  };

  const addTerritory = (addCode, excludedCodes, territoryKey) => {
    props.setSelectedCodes([...new Set([...props.selectedCodes, addCode])]);
    if (!excludedCodes.filter((code) => code !== addCode).length) {
      flipTerritoryView(territoryKey);
    }
  };

  const removeAllFromTerritory = (removeCodes) => {
    props.setSelectedCodes(
      props.selectedCodes.filter((code) => !removeCodes.includes(code))
    );
  };

  const renderSelectedTerritories = (territory) => {
    const territoryKey = territory.key;
    const regionsStateMapLocal = _.cloneDeep(regionViewStateMap);
    const includedChips = territory.included.sort((a,b) => region_title_index_map[a].localeCompare(region_title_index_map[b])).map((code) => (
      <Tag
        closable
        onClose={(e) => removeTerritory(code)}
        color="success"
        key={`included-${code}`}
      >
        {region_title_index_map[code]} {props.showStateString ? <StateCodeRender stateCode={code}/> : null}
      </Tag>
    ));
    const excludedChips = territory.excluded.map((code) => (
      <Tag
        closable
        onClose={(e) => addTerritory(code, territory.excluded, territoryKey)}
        color="error"
        key={`excluded-${code}`}
      >
        {region_title_index_map[code]} {props.showStateString ? <StateCodeRender stateCode={code}/> : null}
      </Tag>
    ));

    const isAllIncluded =
      includedChips.length ===
      GEO_LIST_TREE_STRUCTURE.find((o) => o.code === territoryKey).children
        .length;
    if (isAllIncluded) {
      regionsStateMapLocal[territoryKey].showIncludes = true;
    }

    let chips = regionsStateMapLocal[territoryKey].showIncludes
      ? includedChips
      : excludedChips;
    let viewMoreButton = null;
    const l = chips.length;
    if (l > 5) {
      viewMoreButton =
        regionsStateMapLocal[territoryKey].count > 5 ? (
          <Tag
            onClick={() => handleViewLess(territoryKey)}
            color={regionsStateMapLocal[territoryKey].showIncludes ? "success" : "error"}
            className={"pointer"}
          >
            -
          </Tag>
        ) : (
          <Tag
            onClick={() => handleViewMore(territoryKey, l)}
            color={regionsStateMapLocal[territoryKey].showIncludes ? "success" : "error"}
            className={"pointer"}
          >
            +{l - 5}
          </Tag>
        );
    }
    chips = chips.slice(0, regionsStateMapLocal[territoryKey].count);

    return includedChips.length ? (
      <div className="region-group">
        <div className="group-name">
          <Space size={4}>
            {territory.title ?? territoryKey}
            <Tooltip
              title={`Remove all regions from ${territory.title ?? territoryKey}`}
            >
              <Button icon={<CloseOutlined/>} type="link" onClick={() => removeAllFromTerritory(territory.included)}/>
            </Tooltip>
            {territoryKey === "US" && props.selectedRegionsLegend && props.selectedCodes.length ? (
              <Space size={8}>
                <img src="/svg_icons/vertical-separator.svg" />
                {props.selectedRegionsLegend}
              </Space>
            ) : null}
          </Space>
          <Space size={8}>
            <Radio.Group
              className="region-selectot-radio-btns"
              disabled={territory.excluded.length === 0}
              onChange={() => flipTerritoryView(territoryKey)}
              defaultValue={"MspaOptOutOptionMode"}
              value={regionsStateMapLocal[territoryKey].showIncludes}
              options={[
                {
                  label: <Typography.Text type={regionsStateMapLocal[territoryKey].showIncludes ? "success" : null}>Included {`(${territory.included.length})`}</Typography.Text>,
                  value: true
                },
                {
                  label: <Typography.Text type={!regionsStateMapLocal[territoryKey].showIncludes ? "danger" : null}>Excluded {`(${territory.excluded.length})`}</Typography.Text>,
                  value: false
                }
              ]}
            />
          </Space>
        </div>
        <div className="group-regions">
          {chips}
          {viewMoreButton}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="selected-regions">
      <div className="heading">
        <Typography.Text strong>Selected</Typography.Text>
        {props.selectedCodes.length ? (
          <Space size={0}>
            <img src="/svg_icons/vertical-separator.svg"/>
            <Popconfirm
              overlayClassName="exit-wizard-confirmation"
              title={
                <div>
                  Are you sure you want to remove all selected {props.entity}?
                </div>
              }
              placement="topRight"
              trigger="click"
              icon={null}
              onConfirm={() => props.setSelectedCodes([])}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon={<CloseOutlined/>} iconPosition="start">Remove all</Button>
            </Popconfirm>
          </Space>
        ) : null}
      </div>
      <Divider style={{margin:0}}/>
      <div
        className={`selected-regions-container ${
          props.selectedCodes.length ? "" : "centered"
        }`}
      >
        {props.selectedCodes.length ? (
          selectedTerritories.map((territory) =>
            renderSelectedTerritories(territory)
          )
        ) : (
          <div>Selected {props.entity} will appear here.</div>
        )}
      </div>
    </div>
  );
};
export default React.memo(SelectedRegionsView, (prevProps, nextProps) =>
  _.isEqual(prevProps.selectedCodes, nextProps.selectedCodes)
);
